<template>
    <v-card class="text-center">
        <v-card-text>
            <v-row class="justify-center mt-2">
                <div class="d-flex align-center" style="background-color:white;">
                    <v-img :src="logo" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="150" height="110"
                        contain></v-img>
                </div>
            </v-row>

            <template v-if="loading">
                <v-row justify="center" class="text-center">
                    <v-col cols="12">
                        <v-progress-circular :size="100" :width="7" color="primary" indeterminate></v-progress-circular>
                    </v-col>
                </v-row>
            </template>

            <template v-if="NoCotiza && !loading">
                <v-row class="">
                    <v-col cols="12" md="12">
                        <div class="text-uppercase">
                            <h2>NO DISPONIBLE</h2>
                        </div>
                    </v-col>
                </v-row>
            </template>

            <template v-if="!NoCotiza && !loading">
                <v-row>
                    <v-col cols="12">
                        <span class="black--text text-h5 font-weight-bold">Pago anual</span>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <span class="black--text text-h4 font-weight-bold">
                            {{cotizacion.total > 0 ? this.$formatMoney(cotizacion.total) : "No disponible"}}
                        </span>
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <v-card  v-if="aseguradora == 'aig'" href="tel:+525588770550" color="primary"
                            class="bottonLlamada d-flex align-center justify-center text-subtitle-1">
                            <div>
                                <span class="white--text"> Marcar a un asesor  <br> <span class="pl-5 white--text">55 8877-0550</span> </span>
                            </div>
                        </v-card >

                        <v-card  v-if="aseguradora == 'gnp'" href="tel:+525588770551" color="primary"
                            class="bottonLlamada d-flex align-center justify-center text-subtitle-1">
                            <div>
                                <span class="white--text">Marcar a un asesor <br> <span class="pl-5 white--text">55 8877-0551</span></span>
                            </div>
                        </v-card >

                        <v-card  v-if="aseguradora == 'qualitas'" href="tel:+525588770552" color="primary"
                            class="bottonLlamada d-flex align-center justify-center text-subtitle-1">
                            <div>
                                <span class="white--text">Marcar a un asesor <br> <span class="pl-5 white--text">55 8877-0552</span> </span>
                            </div>
                        </v-card >
                    </v-col>
                </v-row>

                <v-row dense>
                    <v-col cols="12">
                        <v-btn color="primary" block @click="showDetails=!showDetails">{{showDetails ? 'Ver menos' :
                        'Ver Detalles'}}</v-btn>
                    </v-col>
                </v-row>

                <v-row v-if="showDetails" dense>
                    <v-col cols="12">
                        <div class="row mt-3" v-if="aseguradora=='qualitas'&&plan=='amplia'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">5%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$300,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Muerte del conductor por AA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos Legales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Asistencia Vial Quálitas</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto Sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='qualitas'&&plan=='amplia-plus'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">5%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$300,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Muerte del conductor por AA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos Legales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Asistencia Vial Quálitas</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto Sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='qualitas'&&plan=='limitada'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$300,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Muerte del conductor por AA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos Legales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Asistencia Vial Quálitas</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto Sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='qualitas'&&plan=='rc'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">5%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$300,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Muerte del conductor por AA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos Legales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Asistencia Vial Quálitas</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto Sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <!-- GNP -->
                        <div class="row mt-3" v-if="aseguradora=='gnp'&&plan=='amplia'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">5%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Cristales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">20%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Club GNP autos</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Ayuda Llantas/Rines</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Llaves de repuesto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Pago Cero</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Siempre en agencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='gnp'&&plan=='amplia-plus'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">5%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Cristales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">20%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Club GNP autos</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10 días</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Ayuda Llantas/Rines</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Un evento por año póliza</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Llaves de repuesto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Un evento por año póliza</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Pago Cero</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Siempre en agencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='gnp'&&plan=='limitada'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Cristales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Club GNP autos</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Ayuda Llantas/Rines</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Llaves de repuesto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Pago Cero</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Siempre en agencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='gnp'&&plan=='rc'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Cristales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Club GNP autos</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Ayuda Llantas/Rines</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Llaves de repuesto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Pago Cero</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Siempre en agencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                        </div>
                        <!-- AIG -->
                        <div class="row mt-3" v-if="aseguradora=='aig'&&plan=='amplia-plus'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">5%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$4,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$300,000.00</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">400 MXN diarios hasta 27 días</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Eliminación de deducible PT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Reembolso de primas PT o RT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Servicios de asistencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Defensa legal</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='aig'&&plan=='amplia'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000.00</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Eliminación de deducible PT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Reembolso de primas PT o RT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Servicios de asistencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Defensa legal</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='aig'&&plan=='limitada'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000.00</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Eliminación de deducible PT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Reembolso de primas PT o RT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Servicios de asistencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Defensa legal</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>
                        <div class="row" v-if="aseguradora=='aig'&&plan=='rc'">
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Daños Materiales</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Robo total</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">10%</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$3,000,000</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Gastos médicos de ocupantes</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">$200,000.00</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Extensión RC</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>

                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Auto sustituto</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Eliminación de deducible PT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Reembolso de primas PT o RT</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">-</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Servicios de asistencia</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">RC daños a terceros EUA</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-8">
                                <span style="font-size:12px;margin:0px">Defensa legal</span>
                            </div>
                            <div style="justify-content: start;display: flex;align-items: center; padding: 0px 12px 0px;"
                                class="col-4">
                                <span style="font-size:12px;margin:0px">Amparada</span>
                            </div>
                        </div>

                        <div v-if="aseguradoraMeta.aseguradora == 'interprotecciones'">
                            <div v-for="(detalle, index) in getDetallesPlanAseguradora({ aseguradora, plan }).coberturas" :key="index">
                            <v-row dense :style="{ 'background-color': index % 2 ? '#ececec' : '#fff' }">
                                <v-col cols="8">
                                    <span><strong>{{ detalle.nombre }}</strong></span>
                                </v-col>
                                <v-col cols="4" align-self="center">
                                    <span>{{ detalle.deducible || detalle.SA }}</span>
                                </v-col>
                            </v-row>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { cotiza } from '@/util/CotizaHelper.js';

import { getDetallesPlanAseguradora } from './planesAseguradoras';

export default {
    name: 'Cotizacion.CotizacionIndividualComparador',
    props: {
        aseguradora: {
            type: String,
            require: true
        },
        logo: {
            require: true
        },
        plan: {
            type: String,
            default: 'amplia'
        },
        usuarioJson: {
            require: true
        },
        vehiculo: {
            require: true
        },
        marca: {
            require: true
        },
        submarca: {
            require: true
        },
        modelo: {
            require: true
        },
        origen: {
            type: String,
            default: "Organico"
        },
        CotizacionGeneral: {
            require: true
        }
    }, data: function () {
        return {
            loading: true,
            cotizacion: null,
            NoCotiza: false,
            showDetails: false,
            contrato: '',
        }
    }, computed: {
        ...mapState(['aseguradoraMeta', "aseguradoras", 'cotizacionGeneral']),
    }, mounted() {
        this.cotizar();
    }, watch: {
        plan: function (val) {
            this.CanceledRequest();
            this.cotizar();
            this.$emit("update:plan", val);
        }
    }, methods: {
        getDetallesPlanAseguradora,

        async cotizar() {
            try {
                this.loading = true;
                this.cotizacion = await cotiza(this.modelo, this.marca, this.submarca, this.vehiculo, this.plan, this.aseguradora, this.vehiculo.id, "92dabb35-e35b-4413-b29a-5d0e3fd69ba5", this.usuarioJson, this.aseguradoraMeta.portal, this.aseguradoraMeta.aseguradora, this.cotizacionGeneral, this.$getAge(this.usuarioJson.birthYear), this.origen, this.aseguradoraMeta.partner);
                this.loading = false;

                // verifico que la respuesta de la cotizacion no sea fallida
                if (this.cotizacion.status != 'FAILED') {
                    this.NoCotiza = false;

                    // se agrego las aseguradoras que tuvieron datos de cotizacion
                    this.$store.commit('setCotizacionesComparadoresResultado', {
                        nombre: this.aseguradora,
                        logo: this.logo,
                        cotizacion: this.cotizacion
                    });
                    this.$store.commit('setCotizaciones', this.cotizacion);
                } else {
                    // en caso de que sea fallida, cambio para mostrar el mensaje de no disponible
                    this.NoCotiza = true;
                    this.$store.commit('setCotizaciones', null);
                    this.$store.commit('setNoCotiza', null);
                }

            } catch (exception) {
                console.error(exception)
                this.loading = false;
                this.NoCotiza = true;
                this.$store.commit('setCotizaciones', null);
                this.$store.commit('setNoCotiza', null);
            }
        },
        async CanceledRequest() {
            this.$store.commit('setClearAllProccessCotizaciones');

        }
    }
}

</script>

<style scoped>
span {
    color: black;
}

.bottonLlamada {
    background-image: url('../../../../assets/icons/segurify_icono_llamada_final_v1_270922_001.svg');
    background-repeat: no-repeat;
    background-size: 110%;
    background-position: bottom;
}

@media (min-width: 420px) {
    .bottonLlamada {
        background-size: 90%;
        background-position: center;
    }
}

@media (min-width: 575px) {
    .bottonLlamada {
        background-size: 70%;
    }
}

@media (min-width: 670px) {
    .bottonLlamada {
        background-size: 55%;
    }
}

@media (min-width: 760px) {
    .bottonLlamada {
        background-size: 40%;
    }
}

@media (min-width: 960px) {
    .bottonLlamada {
        background-size: 100%;
    }
}


@media (min-width: 992px) {
    .bottonLlamada {
        background-size: 100%;
        background-position: bottom;
    }
}

@media (min-width: 1024) {
    .bottonLlamada {
        background-size: 90%;
    }
}

</style>