<template>
    <v-img
        class="tracker"
        :src="aseguradoraMeta.banner"
        alt="Seguros Segurify - Cotizador de Seguros de Auto"
        height="550"
        contain
    ></v-img>
</template>

<script>
import { mapState } from 'vuex'
export default {
    computed: {
      ...mapState(['aseguradoraMeta'])
    }, 
    watch: {
        // "$vuetify.breakpoint.xs": function(val){
        //     console.log("xs", val)
        // },
        // "$vuetify.breakpoint.sm": function(val){
        //     console.log("sm",val)
        // },
        // "$vuetify.breakpoint.md": function(val){
        //     console.log("md", val)
        // },
        // "$vuetify.breakpoint.lg": function(val){
        //     console.log("lg", val)
        // },
        // "$vuetify.breakpoint.xl": function(val){
        //     console.log("xl",val)
        // }
    }
}
</script>

<style scoped>
    .tracker{
        position: sticky;
        top: 0;
    }
</style>