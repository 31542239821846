import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
// import VStepperIcon from './step/VStepperIcon.vue';
import VStepperStepIcon from './step/VStepperStepIcon.vue';
const host = window.location.host;
const parts = host.split('.');
const domainLength = 3; 

const aseguradora = () => {
    if (parts.length === (domainLength - 1) || parts[0] === 'www') {
      return "segurify";
    } else if (parts[0] === 'ana') {
      return "ana";
    }
    return "segurify";
  };
var color="#152189";
if(aseguradora()==='ana'){
    color="#c2002f";
}
Vue.use(Vuetify, {
    components: {
        // VStepperIcon,
        VStepperStepIcon,
    },
});

export default new Vuetify({
    theme: {
        dark: false,
        themes: {
          light: {
            primary:color,
          },
        },
      },
});
