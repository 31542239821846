export default class Verificacion{

    constructor(){
        this.campagin = 'Organico';
        this.fuente = 'Organico';
        this.getParamsUrl();
    }

    getParamsUrl() {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        if(urlParams.has('campagin')){
            this.campagin = urlParams.get('campagin')
        }else{
            this.campagin = 'Organico';
        }
        
        if(urlParams.has('fuente')){
            this.fuente = urlParams.get('fuente')
        }else{
            this.fuente = 'Organico';
        }
    }

    getOrigen(){
        return this.fuente;
    }

    getCampagin(){
        return this.campagin;
    }

    async sendLeads(uuid, email){
        try{
            await fetch(`https://www.segurifyseguros.com/lead_segurify.php?campagin=${this.campagin}&fuente=${this.fuente}&uuid=${uuid}&correo=${email}`, {
                mode: 'no-cors'
            });
        }catch(exception){
            console.error(exception);
        }
    }
}