<template>
  <v-row justify="center">
    <div class="text-center">
      <h1>¡Lo sentimos!</h1>
      <h2>No encontramos la página que buscas</h2>
      <v-img
        :src="notFound"
        alt="Seguros Segurify - Cotizador de Seguros de Auto"
        contain
      ></v-img>
    </div>
  </v-row>
</template>

<script>
import Error from "@/assets/icons/icon_error404.svg";
export default {
  data: function () {
    return {
      notFound: Error,
    };
  },
};
</script>