import Vue from 'vue'
import './plugins/axios'
import './plugins/mask'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import VueMeta from 'vue-meta';
import VueMask from 'v-mask';
// import LogoAna from "./assets/logos/logo_anaSeguros.png"
// import bannerAna from "./assets/banner/bannerComparadorAna.gif"

import VueGtag from "vue-gtag";
// import VueGtm from '@gtm-support/vue2-gtm';

import VModal from 'vue-js-modal'
import { extend, configure } from 'vee-validate';

import { required } from 'vee-validate/dist/rules';
import { email } from 'vee-validate/dist/rules';
import { min } from 'vee-validate/dist/rules';
import { regex } from 'vee-validate/dist/rules';
Vue.use(VModal)

Vue.use(VueMask);
Vue.use(VueMeta)
Vue.config.productionTip = false

configure({
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid',
    dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
    // ...
  }
})
// FIN CLASES VEEVALIDATE
//INICIO REGLAS DE DE VEEVALIDATE
extend('secret', {
  validate: value => value === 'example',
  message: 'This is not the magic word'
});
extend('required', {
  ...required,
  message: 'Este campo es obligatorio'
});
extend('email', {
  ...email,
  message: 'Debe ser un correo válido'
});

extend('min', {
  ...min,
  message: 'Debe ser mayor a 3 caracteres'
});

extend('regex', {
  ...regex,
  message: 'No es válido'
});
import "@/assets/main.css"
Vue.prototype.$getAge = (year)=>{
  let age = 0;
  let today = new Date();
  age = today.getFullYear() - year;
  return age;
}
Vue.prototype.$generateCotizacion = (max = 6)=>{
  const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';  
  // const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result1= '';
  const charactersLength = characters.length;
  for ( let i = 0; i < max; i++ ) {
      result1 += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result1;
}

Vue.prototype.$uuidAbandono = () =>{
  let date = new Date()
  let minutes = date.getMinutes()
  let hour = date.getHours()
  let day = date.getDate()
  let newDay = day < 10 ? `0${day}` : day
  let month = date.getMonth() + 1
  let newMonth = month < 10 ? `0${month}` : month
  let year = date.getFullYear()
  let aleatorio = Math.floor((Math.random() * 9999) + 1); 
  let completo = `${year}${newMonth}${newDay}${hour}${minutes}${aleatorio}`
  return completo
}

Vue.prototype.$formatMoney = (money = 0)=>{
  let divisa = new Intl.NumberFormat('es-MX',{
    style:'currency',
    currency: 'MXN'
  });
  return divisa.format(money)
}

const host = window.location.host;
const parts = host.split('.');
const domainLength = 3; // route1.example.com => domain length = 3

const aseguradora = () => {
  if (parts.length === (domainLength - 1) || parts[0] === 'www') {
    return "comparador";
  } else if (parts[0] === 'ana' || parts[0] === 'ana-pruebas') {
    return "ana";
  } else if (parts[0] === 'coru' || parts[0] === 'coru-pruebas') {
    return "coru";
  }else if (parts[0] === 'axa' || parts[0] === 'axa-pruebas' || parts[0] === 'autos-axa' ) {
    return "axa";
  }else if (parts[0] === 'hdi' || parts[0] === 'hdi-pruebas') {
    return "hdi";
  } else if (parts[0] === 'gnp' || parts[0] === 'gnp-pruebas' ) {
    return "gnp";
  }else if (parts[0] === 'qualitas' || parts[0] === 'qualitas-pruebas' ) {
    return "qualitas";
  }else if (parts[0] === 'aig' || parts[0] === 'aig-pruebas' ) {
    return "aig";
  }else if (parts[0] === 'bradescard' || parts[0] === 'bradescard-pruebas' ) {
    return "bradescard";
  }else if (parts[0] === 'sura' || parts[0] === 'sura-pruebas' ) {
    return "sura";
  }
  else if (parts[0] === 'lav' || parts[0] === 'lav-pruebas' ) {
    return "lav";
  }
  else if (parts[0] === 'adt' || parts[0] === 'adt-pruebas' ) {
    return "adt";
  }
  else if (parts[0] === 'autocosmos' || parts[0] === 'autocosmos-pruebas' ) {
    return "autocosmos";
  }
  return "comparador";
};

if(aseguradora()=='coru'){
  Vue.use(VueGtag, {
    config: { id: "GTM-P5ZFJFJ" },
  });
}
if(aseguradora()=='axa'){
  Vue.use(VueGtag, {
    config: { id: "GTM-W3CWXMJ" },
  });
}
if(aseguradora()=='hdi'){
  Vue.use(VueGtag, {
    config: { id: "GTM-MNM9MP7" },
  });
}
if(aseguradora()=='gnp'){
  Vue.use(VueGtag, {
    config: { id: "GTM-W6KSX2M" },
  });
}
if(aseguradora()=='qualitas'){
  Vue.use(VueGtag, {
    config: { id: "GTM-WVRD2MB" },
  });
}
if(aseguradora()=='aig'){
  Vue.use(VueGtag, {
    config: { id: "GTM-TS8L98V" },
  });
}
if(aseguradora()=='ana'){
  Vue.use(VueGtag, {
    config: { id: "GTM-5NNXQT6" },
  });
}
if(aseguradora()=='sura'){
  Vue.use(VueGtag, {
    config: { id: "GTM-PKMBFG5" },
  });
}
if(aseguradora()=='comparador'){
  Vue.use(VueGtag, {
    config: { id: "GTM-KM2Q2P3" }
  });
}


store.dispatch('getAseguradora', aseguradora());

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
