<template>
  <v-card class="mb-12">
    <v-row>
      <v-col cols="12">
        <v-form ref="formData">
          <v-container>
            <v-row dense>
              <v-col v-if="aseguradoraMeta.partner == 'adt'" cols="12">
                <v-text-field
                  v-model="numCliente"
                  :counter="9"
                  :maxLength="9"
                  label="Número de cliente*"
                  color="success"
                  :rules="[
                    rules.required,
                    (v) =>
                      !v ||
                      v[0] == '7' ||
                      'El número de cliente debe comenzar con con 7',
                    rules.longDigit(9),
                  ]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  v-model="nombre"
                  :counter="40"
                  label="Nombre*"
                  color="success"
                  :maxLength="40"
                  :rules="[rules.required, localRules.minDigit(3)]"
                >
                </v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="apellidoPaterno"
                  :counter="40"
                  :maxLength="40"
                  label="Apellido Paterno*"
                  color="success"
                  :rules="[rules.required, localRules.minDigit(2)]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="apellidoMaterno"
                  :counter="40"
                  :maxLength="40"
                  label="Apellido Materno"
                  color="success"
                  :rules="[rules.required, localRules.minDigit(2)]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="fechaNacimiento"
                  label="Fecha de nacimiento*"
                  placeholder="dd/mm/aaaa"
                  prepend-icon="mdi-calendar"
                  v-mask="'##/##/####'"
                  color="success"
                  :rules="[rules.required, rules.validDate]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="correo"
                  label="E-mail*"
                  required
                  :rules="[rules.required, rules.email]"
                  color="success"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="telefono"
                  label="Teléfono*"
                  :counter="14"
                  v-mask="'(##) #### ####'"
                  color="success"
                  :rules="[rules.required, rules.celular, rules.longDigit(14)]"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="4">
                <v-radio-group
                  v-model="sexo"
                  row
                  :rules="[rules.required]"
                  color="success"
                >
                  <v-radio label="Masculino" value="M"></v-radio>
                  <v-radio label="Femenino" value="F"></v-radio>
                </v-radio-group>
              </v-col>

              <v-col cols="12" md="4">
                <v-select
                  v-model="estadoCivil"
                  :items="['Soltero', 'Casado']"
                  label="Estado Civil*"
                  :rules="[rules.required]"
                  color="success"
                ></v-select>
              </v-col>

              <v-col cols="12" md="4">
                <v-text-field
                  v-model="codigoPostal"
                  label="Código postal*"
                  v-mask="'#####'"
                  placeholder="00000"
                  :rules="[rules.required, rules.longDigit(5)]"
                  color="success"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
    </v-row>

    <v-row class="text-center" justify="center" align="center" dense>
      <v-checkbox v-model="terminos"></v-checkbox>
      <label>
        Acepto los <DialogTerminos /> y el
        <DialogPrivacidad />
        <span v-if="aseguradoraMeta.partner == 'adt'" class="ml-2"
          ><strong
            >"A partir de este momento aplica las políticas de seguridad de
            segurify"</strong
          ></span
        >
      </label>
    </v-row>

    <v-row justify="center" class="text-center">
      <v-col cols="12">
        <v-btn
          text
          outlined
          elevation="2"
          class="mr-2"
          @click="$emit('back', 'version')"
          >Regresar</v-btn
        >
        <v-btn
          color="primary"
          @click="cotiza"
          :disabled="!validate || !terminos"
          >Cotizar</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import DialogTerminos from "./DialogTerminos.vue";
import DialogPrivacidad from "./DialogPrivacidad.vue";
import { mapState } from "vuex";

export default {
  components: {
    DialogTerminos,
    DialogPrivacidad,
  },
  data: () => ({
    dateFormatted: null,
    calendarPicker: false,
    sexo: null,
    estadoCivil: null,
    fechaNacimiento: null,
    nombre: null,
    codigoPostal: null,
    apellidoPaterno: null,
    apellidoMaterno: null,
    correo: null,
    valid: false,
    telefono: null,
    terminos: true,
    validate: false,
    numCliente: "7",

    localRules: {
      minDigit(min) {
        return (v) =>
          !v || v.length >= min || `Se requieren ${min} caracteres como mínimo`;
      },
    },
  }),
  computed: {
    ...mapState(["rules", "aseguradoraMeta", "abandono"]),
  },

  mounted() {
    if (this.aseguradoraMeta.partner == "adt") this.terminos = false;
  },

  watch: {
    nombre: function () {
      this.checkValidate();
    },
    apellidoPaterno: function () {
      this.checkValidate();
    },
    fechaNacimiento: function () {
      this.checkValidate();
    },
    correo: function () {
      this.checkValidate();
    },
    telefono: function () {
      this.checkValidate();
    },
    sexo: function () {
      this.checkValidate();
    },
    estadoCivil: function () {
      this.checkValidate();
    },
    codigoPostal: function () {
      this.checkValidate();
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    reset() {
      this.$refs.formData.resetValidation();
    },
    cotiza() {
      let valid = this.$refs.formData.validate();
      if (!valid) {
        this.$emit("cotiza", { valid: false });
        return false;
      }

      let tel = this.telefono.replaceAll("(", "");
      tel = tel.replaceAll(")", "");
      tel = tel.replaceAll(" ", "");

      const [day, month, year] = this.fechaNacimiento.split("/");
      var usuarioJson = {
        civilStatus: this.estadoCivil,
        cp: this.codigoPostal,
        birthYear: year,
        birthMonth: month,
        birthDay: day,
        name: this.nombre,
        lastname: this.apellidoPaterno,
        lastname2: this.apellidoMaterno,
        email: this.correo,
        phone: tel,
        sexo: this.sexo,
        ...(this.aseguradoraMeta.partner == "adt" && {
          idCliente: this.numCliente,
        }),
      };
      this.log(usuarioJson);
      this.$emit("cotiza", { valid: true, data: usuarioJson });
    },
    async logAntesContacto() {
      try {
        this.axios.post("/v1/web/log", {
          id: this.abandono,
          landing: this.aseguradoraMeta.aseguradora,
          paso: "antesContacto",
          valores: [],
          fuente: this.$route.query.fuente,
        });
      } catch (exception) {
        console.error(exception);
      }
    },
    async log(row) {
      try {
        this.axios.post("/v1/web/log", {
          id: this.abandono,
          landing: this.aseguradoraMeta.aseguradora,
          paso: "contacto",
          valores: [
            { birthDay: row.birthDay },
            { birthMonth: row.birthMonth },
            { birthYear: row.birthYear },
            { civilStatus: row.civilStatus },
            { genero: row.sexo },
            { codigoPostal: row.cp },
            { nombre: row.name },
            { apellidop: row.lastname },
            { apellidom: row.lastname2 },
            { mail: row.email },
            { celular: row.phone },
          ],
          fuente: this.$route.query.fuente,
        });
      } catch (exception) {
        console.error(exception);
      }
    },
    checkValidate() {
      let valid = this.$refs.formData.validate();
      if (valid) {
        this.validate = true;
      } else {
        this.validate = false;
      }
    },
  },
};
</script>
