<template>
  <v-app-bar
    app
    color="primary"
    height="83"
    dark
    dense
    elevation="6"
    :style="`--border: ${aseguradoraMeta.lineBar}`"
  >
    <div
      class="d-flex align-center"
      :style="
        this.aseguradoraMeta.aseguradora == 'comparador'
          ? ''
          : `background-color:${aseguradoraMeta.bg_logo}`
      "
    >
      <v-container>
        <v-img
          :src="aseguradoraMeta.img"
          alt="Seguros Segurify - Cotizador de Seguros de Auto"
          :width="aseguradoraMeta.attribs.width"
          :height="aseguradoraMeta.attribs.height"
          contain
          :class="`logo-${aseguradoraMeta.portal}`"
        ></v-img>
      </v-container>
    </div>

    <v-spacer></v-spacer>

    <v-btn text @click="getRoute('Home')" v-text="'¡Cotiza!'"></v-btn>

    <template v-if="aseguradoraMeta.has_menu">
      <v-menu rounded offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text v-bind="attrs" v-on="on"> Sobre Nosotros</v-btn>
        </template>

        <v-list>
          <v-list-item @click="getRoute('About')">
            <v-list-item-title>Acerca de Segurify</v-list-item-title>
          </v-list-item>

          <v-list-item @click="getRoute('Aviso')">
            <v-list-item-title>Aviso de privacidad</v-list-item-title>
          </v-list-item>

          <v-list-item @click="getRoute('Terminos')">
            <v-list-item-title>Términos y condiciones</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu rounded offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn text v-bind="attrs" v-on="on">Ayuda</v-btn>
        </template>

        <v-list>
          <v-list-item @click="getRoute('Ayuda')">
            <v-list-item-title>Preguntas Frecuentes</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-btn text @click="getRoute('Siniestro')" v-text="'Siniestro'"></v-btn>
      <v-btn text @click="getRoute('Contacto')" v-text="'Contacto'"></v-btn>
    </template>

    <v-btn v-show="aseguradoraMeta.has_telefono" text @click="tagManager">
      <span>TELÉFONO: 5588730915</span>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Base",
  data: () => ({
    image: null,
  }),
  computed: {
    ...mapState(["aseguradoraMeta"]),
  },
  beforeMount() {},
  methods: {
    getRoute(name = "Home") {
      if (name == "Home") {
        if (this.$route.name == "Home") {
          location.reload();
        } else {
          this.$router
            .push({ name: name, params: { aseguradora: null }, replace: true })
            .catch(() => {});
        }
      }
      this.$router
        .push({ name: name, params: { aseguradora: null }, replace: true })
        .catch(() => {});
    },
    tagManager() {
      if (this.aseguradoraMeta.tag) {
        this.$gtag.event("lead_success_phone", {
          event_category: "click",
          event_label: "Segurify Auto",
        });
      }

      let a = document.createElement("a");
      a.href = "tel:+525588730915";
      a.click();
    },
  },
};
</script>

<style>
.v-toolbar__content {
  /* border-bottom: 8px solid #555559cc !important; */
  border-bottom: 8px solid var(--border) !important;
}

.logo-autocosmos {
  filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(181deg)
    brightness(105%) contrast(100%);
}
</style>
