<template>
    <v-row>
        <v-col cols="12">
            <v-card flat>
                <v-card-title class="text-h5 lighten-2">PREGUNTAS FRECUENTES</v-card-title>
                <v-card-text class="mt-4">
                    <v-row justify="center">
                        <v-expansion-panels accordion>
                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿Qué es Segurify?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">Somos una plataforma digital que te ayudará a encontrar de una forma rápida y sencilla el mejor seguro para tu auto.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿Cómo funciona Segurify Auto?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">¡Muy fácil!, solo debes seguir estos pasos: <br/>1.- Dinos el año, modelo, marca y submarca de tu vehículo. <br/>2.- Registra los datos del conductor habitual, así como los datos de contacto.<br/>3.- ¡Espera nuestra llamada! Uno de nuestros ejecutivos se pondrá en contacto contigo para mostrarte cual es la mejor protección para tu auto.</p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿Por qué asegurar mi auto?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">
                                        En <b>SEGURIFY</b> sabemos que tu auto es una inversión que no puedes arriesgar por lo que, al contar con un seguro de auto proteges tu inversión y además, tus acompañantes contarán con un respaldo en caso de verse involucrados en un accidente dentro del vehículo. Un seguro de auto te brindará protección médica en caso de accidentes, podría pagar daños por robo, accidentes o incluso, desastres naturales.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    <label for="">
                                        ¿Qué tipo de vehículos puedo asegurar en <b>Segurify</b>?
                                    </label>
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">
                                        Automóviles Nacionales, con factura expedida en la República Mexicana.<br/>De uso particular, con una antigüedad máxima de 20 años. <br/>Actualmente no aseguramos autos fronterizos, legalizados ni los denominados “Salvamento”.<br>Si requieres asegurar un auto de otra característica, déjanos tus datos y con gusto te contactamos para asesorarte.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿Qué es el Deducible?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">
                                        Es la participación del asegurado como responsabilidad ante un siniestro.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿En qué coberturas debo pagar deducible?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">
                                        Únicamente en Daños Materiales y Robo Total de tu vehículo.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿Qué debo hacer en caso de siniestro?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">
                                        Es importante notifiques inmediatamente a la aseguradora del tipo de siniestro presentado, ellos te solicitarán el número de póliza, dirección exacta donde ocurrió el mismo así como los datos del conductor habitual.<br>Recuerda: Es sumamente importante que tu póliza se encuentre pagada para contar con la protección ante un accidente o robo total de tu auto.
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>

                            <v-expansion-panel>
                                <v-expansion-panel-header>
                                    ¿Con quién me comunico si tengo alguna duda del seguro que contraté?
                                </v-expansion-panel-header>

                                <v-expansion-panel-content>
                                    <p class="text-body-2">
                                        ¡Estamos para servirte! Por favor llámanos al 5588730915 o si lo prefieres, envíanos un mail a <a href="mailto:atencionaclientes@segurify.com">atencionaclientes@segurify.com</a>
                                    </p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name:"AyudaView",
    data: function(){
        return{
            myItems: [
                {
                    title: '¿Qué es Segurify?',
                    value: 'Somos una plataforma digital que te ayudará a encontrar de una forma rápida y sencilla el mejor seguro para tu auto.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿Cómo funciona Segurify Auto?',
                    value: '¡Muy fácil!, solo debes seguir estos pasos: <br/>1.- Dinos el año, modelo, marca y submarca de tu vehículo. <br/>2.- Registra los datos del conductor habitual, así como los datos de contacto.<br/>3.- ¡Espera nuestra llamada! Uno de nuestros ejecutivos se pondrá en contacto contigo para mostrarte cual es la mejor protección para tu auto.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿Por qué asegurar mi auto?',
                    value: 'En <b>SEGURIFY</b> sabemos que tu auto es una inversión que no puedes arriesgar por lo que, al contar con un seguro de auto proteges tu inversión y además, tus acompañantes contarán con un respaldo en caso de verse involucrados en un accidente dentro del vehículo. Un seguro de auto te brindará protección médica en caso de accidentes, podría pagar daños por robo, accidentes o incluso, desastres naturales.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿Qué tipo de vehículos puedo asegurar en <b>Segurify</b>?',
                    value: 'Automóviles Nacionales, con factura expedida en la República Mexicana.<br/>De uso particular, con una antigüedad máxima de 20 años. <br/>Actualmente no aseguramos autos fronterizos, legalizados ni los denominados “Salvamento”.<br>Si requieres asegurar un auto de otra característica, déjanos tus datos y con gusto te contactamos para asesorarte.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿Qué es el Deducible?',
                    value: 'Es la participación del asegurado como responsabilidad ante un siniestro.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿En qué coberturas debo pagar deducible?',
                    value: 'Únicamente en Daños Materiales y Robo Total de tu vehículo.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿Qué debo hacer en caso de siniestro?',
                    value: 'Es importante notifiques inmediatamente a la aseguradora del tipo de siniestro presentado, ellos te solicitarán el número de póliza, dirección exacta donde ocurrió el mismo así como los datos del conductor habitual.<br>Recuerda: Es sumamente importante que tu póliza se encuentre pagada para contar con la protección ante un accidente o robo total de tu auto.',
                    category: 'Sobre Segurify'
                },
                {
                    title: '¿Con quién me comunico si tengo alguna duda del seguro que contraté?',
                    value: '¡Estamos para servirte! Por favor llámanos al 5588730915 o si lo prefieres, envíanos un mail a <a href="mailto:atencionaclientes@segurify.com">atencionaclientes@segurify.com</a>',
                    category: 'Sobre Segurify'
                }
            ]
        }
    }
}
</script>

<style>

</style>
