import Vue from 'vue'
import VueRouter from 'vue-router'

// import Base from "@/Base.vue";
import Home from '@/views/Home.vue'

import NosotrosView from "@/views/home/static/NosotrosView.vue"
import TerminosView from "@/views/home/static/TerminosView.vue"
import PrivacidadView from "@/views/home/static/PrivacidadView.vue"
import AyudaView from "@/views/home/static/AyudaView.vue"
import SiniestroView from "@/views/home/static/SiniestroView.vue"
import ContactoView from "@/views/home/static/ContactoView.vue"
import PageNotFound from '@/views/components/PageNotFound.vue'
import Tractos from '@/views/tractos/Tractos.vue'
// import { aseguradora, patchs } from "@/plugins/parameters.js";

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   component: Base,
  //   redirect: { name: 'Home' },
  //   children: [
  //     {
  //       path: '',
  //       name: 'Home',
  //       component: Home
  //     },
  //     {
  //       path: 'acerca-de',
  //       name: 'About',
  //       component: NosotrosView
  //     },
  //     {
  //       path: 'aviso',
  //       name: 'Aviso',
  //       component: PrivacidadView
  //     },
  //     {
  //       path: 'terminos',
  //       name: 'Terminos',
  //       component: TerminosView
  //     },
  //     {
  //       path: 'siniestro',
  //       name: 'Siniestro',
  //       component: SiniestroView
  //     },
  //     {
  //       path: 'faq',
  //       name: 'Ayuda',
  //       component: AyudaView
  //     },
  //     {
  //       path: 'contacto',
  //       name: 'Contacto',
  //       component: ContactoView
  //     },
  //   ]
  // },
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/acerca-de',
    name: 'About',
    component: NosotrosView
  },
  {
    path: '/aviso',
    name: 'Aviso',
    component: PrivacidadView
  },
  {
    path: '/terminos',
    name: 'Terminos',
    component: TerminosView
  },
  {
    path: '/siniestro',
    name: 'Siniestro',
    component: SiniestroView
  },
  {
    path: '/faq',
    name: 'Ayuda',
    component: AyudaView
  },
  {
    path: '/contacto',
    name: 'Contacto',
    component: ContactoView
  },
  {
    path:'/tractos',
    name:'Tractos',
    component:Tractos
  },
  { 
    path: "*",
    name: '404', 
    component: PageNotFound
  }
]

const router = new VueRouter({
  
  mode: 'history',
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeResolve((to, from, next)=>{
  return next();
});

router.beforeEach((to, from, next)=>{
  return next();
});

export default router
