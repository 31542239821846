<template>
    <v-row>
        <v-col cols="12">
            <v-card flat dense>
                <v-card-title class="text-h5 lighten-2">¿Qué hacer en caso de siniestro?</v-card-title>
                <v-card-text class="mt-4">
                    <v-row dense>
                        <v-col cols="12">
                            <p class="text-body-2">
                                Lo más importante: ¡Mantén la calma! y comunícate de inmediato con tu aseguradora. 
                                También ten a la mano la siguiente información ya que para iniciar el proceso de 
                                siniestro deberás facilitársela a tu ajustador:
                            </p>
                        </v-col>

                        <v-col cols="12">
                            <p class="text-body-2">
                            •	Número de póliza.<br/>
                            •	Nombre del titular de la póliza.<br/>
                            •	Nombre del conductor al momento del siniestro.<br/>
                            •	Número telefónico para estar en contacto con el asegurado.<br/>
                            •	Datos generales del vehículo como marca, submarca, color y placas, para una mejor localización del auto.<br/>
                            •	Lugar del accidente.<br/>
                            </p>
                        </v-col>
                    </v-row>

                    <v-row dense>
                        <v-col cols="12">
                            <p class="text-body-2">
                            Una vez reportado el siniestro, un ajustador acudirá al lugar del accidente 
                            para poder asistirte en lo que necesites. 
                            Te recomendamos tomar las siguientes medidas:
                            </p>
                        </v-col>

                        <v-col cols="12">
                            <p class="text-body-2">
                            •  ¡No dejes abandonado el vehículo después del accidente! Recuerda que el seguro no cubrirá si no estás al momento de que el ajustador llegue a levantar el siniestro.
                            <br/>•  Evita hacer arreglos u ofertas de pago. Siempre deja la atención del siniestro a tu ajustador ya que la aseguradora no se hará responsable de adeudos, transacciones o cualquier otro acto similar realizado sin previa valoración.
                            <br/>•  Llena cuidadosamente el Formato de Declaración que te proporcione el ajustador, debes indicar por escrito tu versión de lo sucedido para determinar tu responsabilidad al momento del siniestro. 
                            <br/>•  ¡No muevas tu auto! Si este no obstruye la circulación, te recomendamos no mover el vehículo del lugar del accidente hasta que el ajustador se encuentre contigo en el lugar y este haya realizado el levantamiento del siniesto.
                            </p>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name:"SiniestroView"
}
</script>

<style>

</style>