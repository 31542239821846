<template>
  <v-app>
    <template v-if="($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) && this.$route.name!='Tractos'">
      <NavMobile />
    </template>

    <template v-if="(!$vuetify.breakpoint.xs && !$vuetify.breakpoint.sm) && this.$route.name!='Tractos'">
      <Nav/>
    </template>

    <v-main v-if="this.$route.name!='Tractos'">
      <router-view />
    </v-main>
    <v-main v-if="this.$route.name=='Tractos'" id="bg" :style="{ backgroundImage: 'url(' + require('@/assets/img/fondo.jpg') + ')' }">
      <router-view />
    </v-main>
    <v-footer color="primary" padless v-if="this.$route.name!='Tractos'">
      <v-row justify="center" no-gutters>
        <v-col class="primary py-4 text-center white--text" cols="12">
          {{ new Date().getFullYear() }}. <strong>Agente autorizado. Consulta nuestros <span
              style="cursor: pointer; text-decoration: underline;" @click="getRoute('Terminos')">Términos y
              condiciones</span> y nuestro <span style="cursor: pointer; text-decoration: underline;"
              @click="getRoute('Aviso')">Aviso de privacidad</span></strong>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import Nav from "@/components/Nav.vue";
import NavMobile from "@/components/NavMobile.vue";

export default {
  name: 'App',
  components: {
    Nav,
    NavMobile
  },
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  mounted() {
    this.$vuetify.theme.themes.light.primary = this.aseguradoraMeta.color;
  },
  methods: {
    getRoute(name = 'Home') {
      this.$router.push({ name: name, replace: true }).catch(() => {})
    },
  },
  metaInfo() {
    return {
      title: `Segurify`,
      meta: [{
        vmid: 'description',
        name: 'description',
        content: this.aseguradoraMeta.meta.descripcion,
      }, {
        vmid: 'keywords',
        name: 'keywords',
        content: "Segurify",
      }]
    }
  },
}
</script>

<style>
  #bg{
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-repeat: no-repeat;
  }
</style>
