export const planesAseguradoras = [
  {
    aseguradora: "qualitasInter",
    planes: [
      {
        nombre: "amplia",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "Valor comercial",
            deducible: "5%",
          },
          {
            nombre: "Robo total",
            SA: "Valor comercial",
            deducible: "10%",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Asistencia vial Quálitas",
            SA: "Amparado",
          },
          {
            nombre: "Gastos legales",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
      {
        nombre: "limitada",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "-",
          },
          {
            nombre: "Robo total",
            SA: "Valor comercial",
            deducible: "10%",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Asistencia vial Quálitas",
            SA: "Amparado",
          },
          {
            nombre: "Gastos legales",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
      {
        nombre: "rc",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "-",
          },
          {
            nombre: "Robo total",
            SA: "-",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Asistencia vial Quálitas",
            SA: "Amparado",
          },
          {
            nombre: "Gastos legales",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
    ],
  },
  {
    aseguradora: "gnpInter",
    planes: [
      {
        nombre: "amplia",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "Valor comercial",
            deducible: "5%",
          },
          {
            nombre: "Cristales",
            SA: "Valor comercial",
            deducible: "20%",
          },
          {
            nombre: "Robo total",
            SA: "Valor comercial",
            deducible: "10%",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Club GNP",
            SA: "Amparado",
          },
          {
            nombre: "Protección Legal",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
      {
        nombre: "limitada",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "-",
          },
          {
            nombre: "Cristales",
            SA: "-",
          },
          {
            nombre: "Robo total",
            SA: "Valor comercial",
            deducible: "10%",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Club GNP",
            SA: "Amparado",
          },
          {
            nombre: "Protección Legal",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
      {
        nombre: "rc",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "-",
          },
          {
            nombre: "Cristales",
            SA: "-",
          },
          {
            nombre: "Robo total",
            SA: "-",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Club GNP",
            SA: "Amparado",
          },
          {
            nombre: "Protección Legal",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
    ],
  },
  {
    aseguradora: "aigInter",
    planes: [
      {
        nombre: "amplia",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "Valor comercial",
            deducible: "5%",
          },
          {
            nombre: "Robo total",
            SA: "Valor comercial",
            deducible: "10%",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Asistencia vial y en viajes",
            SA: "Amparado",
          },
          {
            nombre: "Defensa legal y fianza",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
      {
        nombre: "limitada",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "-",
          },
          {
            nombre: "Robo total",
            SA: "Valor comercial",
            deducible: "10%",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Asistencia vial y en viajes",
            SA: "Amparado",
          },
          {
            nombre: "Defensa legal y fianza",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
      {
        nombre: "rc",
        coberturas: [
          {
            nombre: "Daños materiales",
            SA: "-",
          },
          {
            nombre: "Robo total",
            SA: "-",
          },
          {
            nombre: "RC por daños a terceros",
            SA: "$3,000,000.00",
          },
          {
            nombre: "Gastos médicos de ocupantes",
            SA: "$300,000.00",
          },
          {
            nombre: "Asistencia vial y en viajes",
            SA: "Amparado",
          },
          {
            nombre: "Defensa legal y fianza",
            SA: "Amparado",
          },
          {
            nombre: "Extensión de responsabilidad civil",
            SA: "Amparado",
          },
        ],
      },
    ],
  },
];

export const getDetallesPlanAseguradora = ({ aseguradora, plan }) => {
    console.log({ aseguradora, plan })
  let result = planesAseguradoras.find((e) => e.aseguradora == aseguradora);
  console.log({ result1: result })
  result = result.planes.find((e) => e.nombre == plan);
  console.log({ result2: result })

  return result;
};
