<template>
  <div>
    <v-row justify="space-between" dense>
      <v-col cols="12" sm="12" md="6" lg="6">
        <p style="margin-top: 20px">
          Hola, {{ usuarioJson.name }} Estás cotizando un:
        </p>
        <p style="margin-top: 10px">
          {{ modelo }} {{ marca.marca }}, {{ vehiculo.nombre }}
        </p>
      </v-col>

      <v-col cols="12" sm="12" md="6" lg="6" v-if="aseguradoraMeta.has_botones">
        <v-row dense>
          <v-col cols="12">
            <v-btn color="primary" @click="downloadPdf" :disabled="!send"
              >Descargar Cotización</v-btn
            >
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-btn
              color="primary"
              @click="enviarCotizacion"
              :disabled="!send"
              :loading="loading"
            >
              Enviar Cotización por correo electrónico
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="12">
        <v-divider></v-divider>
      </v-col>
    </v-row>

    <!-- carga de cotizacion general comparador general -->
    <CotizacionComparador
      v-if="aseguradoraMeta.is_compara"
      :usuarioJson="usuarioJson"
      :vehiculo="vehiculo"
      :modelo="modelo"
      :marca="marca"
      :submarca="submarca"
      :origen="origen"
    />

    <v-row class="mt-3" dense v-else>
      <v-col cols="12" md="3" v-for="plan in planes" v-bind:key="plan">
        <CotizacionIndividualAxa
          v-if="aseguradoraMeta.aseguradora == 'axa'"
          :usuarioJson="usuarioJson"
          :vehiculo="vehiculo"
          :modelo="modelo"
          :marca="marca"
          :submarca="submarca"
          :plan="plan"
          :origen="origen"
        />

        <CotizacionIndividual
          v-else
          :usuarioJson="usuarioJson"
          :vehiculo="vehiculo"
          :modelo="modelo"
          :marca="marca"
          :submarca="submarca"
          :plan="plan"
          :origen="origen"
        />
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      width="500"
      hide-overlay
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Correo enviado
        </v-card-title>

        <v-card-text class="mt-4">
          <p style="margin-top: 20px">
            Se ha enviado la cotización a {{ usuarioJson.email }}
          </p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="ModalNoCotiza"
      width="500"
      hide-overlay
      transition="dialog-top-transition"
    >
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Error al cotizar
        </v-card-title>

        <v-card-text class="mt-4">
          <p style="margin-top: 20px">
            Upss... creo que algo salió mal, te buscaremos para ayudarte a
            encontrar el mejor precio para tu seguro de automóvil.
          </p>
          <p class="text-center">¡Elige seguro, elige Segurify!</p>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import CotizacionComparador from "./components/CotizacionComparador.vue";
import CotizacionIndividual from "./components/CotizacionIndividual.vue";
import CotizacionIndividualAxa from "./components/CotizacionIndividualAxa.vue";

// utils
import downloadCotizacion from "@/util/Download.js";
import sendCotizacion from "@/util/sendCotizacion.js";
import Verificacion from "@/util/Verificacion.js";
import {
  enviaLead,
  enviarLeadIndividual,
  enviarLeadComparador,
  envioSinCotizar,
} from "@/util/CotizaHelper.js";

export default {
  name: "Cotizacion",
  props: ["usuarioJson", "vehiculo", "marca", "submarca", "modelo"],
  data: () => ({
    dialog: false,
    loading: false,
    ModalNoCotiza: false,
    cotizacionCorrecto: false,
    doubleCheck: null,
    origen: null,
  }),
  computed: {
    ...mapState([
      "planes",
      "cotizaciones",
      "send",
      "Nocotiza",
      "aseguradoraMeta",
      "plan",
      "cotizacionGeneral",
      "abandono",
    ]),
  },
  beforeMount() {
    this.doubleCheck = new Verificacion();
    this.origen = this.aseguradoraMeta.origen || this.doubleCheck.getOrigen();
  },
  mounted() {
    console.log("Aqui mero: ", this.usuarioJson.email);
    enviaLead(
      this.usuarioJson,
      this.modelo,
      this.marca,
      this.submarca,
      this.vehiculo,
      this.aseguradoraMeta.medioLead || this.aseguradoraMeta.portal,
      this.cotizacionGeneral,
      this.origen,
      this.doubleCheck.getCampagin()
    );

    if (this.aseguradoraMeta.tag) {
      this.$gtag.event("lead_success", {
        event_category: "click",
        event_label: "Segurify Auto",
      });
    }
  },
  watch: {
    send: async function (val) {
      // verifico que todos las cotizaciones se hayan realizado correctamente
      if (val) {
        if (this.aseguradoraMeta.has_send) {
          this.enviarCotizacion();
        }

        let findCotizacion = null;
        // aqui pondria el de envio del endpoint
        for (let i = 0; i < this.cotizaciones.length; i++) {
          if (this.cotizaciones[i] != null) {
            findCotizacion = this.cotizaciones[i];
            break;
          }
        }

        if (this.aseguradoraMeta.is_compara) {
          enviarLeadComparador(
            this.usuarioJson,
            this.modelo,
            this.marca,
            this.submarca,
            this.vehiculo,
            this.aseguradoraMeta.portal,
            findCotizacion,
            this.cotizacionGeneral,
            this.plan
          );
        } else {
          enviarLeadIndividual(
            this.usuarioJson,
            this.modelo,
            this.marca,
            this.submarca,
            this.vehiculo,
            this.aseguradoraMeta.portal,
            findCotizacion,
            this.cotizacionGeneral
          );
        }

        this.doubleCheck.sendLeads(
          this.cotizacionGeneral,
          this.usuarioJson.email
        );
        this.log(this.cotizacionGeneral);
      }
    },
    Nocotiza: function (val) {
      if (val) {
        this.sinCotizacion();
      }
    },
  },
  components: {
    CotizacionComparador,
    CotizacionIndividual,
    CotizacionIndividualAxa,
  },
  methods: {
    async log(id) {
      try {
        this.axios.post("/v1/web/log", {
          id: this.abandono,
          landing: this.aseguradoraMeta.aseguradora,
          paso: "lead",
          valores: [{ lead: id }],
          fuente: this.$route.query.fuente,
        });
      } catch (exception) {
        console.error(exception);
      }
    },
    async downloadPdf() {
      if (this.send) {
        await downloadCotizacion(
          this.cotizaciones,
          this.usuarioJson.name,
          this.usuarioJson.lastname,
          this.aseguradoraMeta.aseguradora,
          this.aseguradoraMeta.is_compara
        );
      }
    },
    async enviarCotizacion() {
      // estes es para enviar el codigo
      if (this.send) {
        this.loading = true;
        let send = await sendCotizacion(
          this.cotizaciones,
          this.aseguradoraMeta.aseguradora,
          this.usuarioJson.email,
          this.aseguradoraMeta.is_compara
        );
        this.loading = false;
        this.dialog = send;
        setTimeout(() => {
          this.dialog = false;
        }, 4000);
      }
    },
    async sinCotizacion() {
      this.ModalNoCotiza = true;
      let data = await envioSinCotizar(
        this.usuarioJson,
        this.aseguradoraMeta.aseguradora,
        this.modelo,
        this.marca,
        this.submarca,
        this.vehiculo,
        this.plan
      );
      this.log(data.uuid);
      this.doubleCheck.sendLeads(data.uuid, this.usuarioJson.email);
      setTimeout(() => {
        this.ModalNoCotiza = false;
      }, 4000);
    },
  },
};
</script>
