export default async function sendCotizacion(
  cotizaciones,
  aseguradora,
  email,
  isCompara
) {
  try {
    let form = {
      correo: email,
      cotizaciones: cotizaciones,
    };
    const url = isCompara
      ? "/v1/cotizacion/correo"
      : `v1/cotizacion/correo/aseguradora/${aseguradora}`;

    console.log({ url });
    await window.axios.post(url, form);
    return true;
  } catch (exception) {
    return false;
  }
}
