<script>
    import { VStepperStep } from 'vuetify/lib/components/VStepper';
    export default {
        name: 'v-stepper-step',
        extends: VStepperStep,
        methods: {
            genStepContent() {
                if (typeof this.$scopedSlots.icon === 'function') {
                    return this.$scopedSlots.icon(this);
                }
                const children = [];
                if (this.hasError) {
                    children.push(this.genIcon(this.errorIcon));
                } else if (this.complete) {
                    if (this.editable) {
                    children.push(this.genIcon(this.editIcon));
                    } else {
                    children.push(this.genIcon(this.completeIcon));
                    }
                } else if (this.$attrs.icon) {
                    children.push(this.genIcon(this.$attrs.icon));
                } 
                else {
                    children.push(String(this.step));
                }
                return children;
            },
        },
    };
</script>