var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{style:(("--border: " + (_vm.aseguradoraMeta.lineBar))),attrs:{"app":"","color":"primary","height":"83","dark":"","dense":"","elevation":"6"}},[_c('div',{staticClass:"d-flex align-center",style:(this.aseguradoraMeta.aseguradora == 'comparador'
        ? ''
        : ("background-color:" + (_vm.aseguradoraMeta.bg_logo)))},[_c('v-container',[_c('v-img',{class:("logo-" + (_vm.aseguradoraMeta.portal)),attrs:{"src":_vm.aseguradoraMeta.img,"alt":"Seguros Segurify - Cotizador de Seguros de Auto","width":_vm.aseguradoraMeta.attribs.width,"height":_vm.aseguradoraMeta.attribs.height,"contain":""}})],1)],1),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s('¡Cotiza!')},on:{"click":function($event){return _vm.getRoute('Home')}}}),(_vm.aseguradoraMeta.has_menu)?[_c('v-menu',{attrs:{"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v(" Sobre Nosotros")])]}}],null,false,770320042)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.getRoute('About')}}},[_c('v-list-item-title',[_vm._v("Acerca de Segurify")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.getRoute('Aviso')}}},[_c('v-list-item-title',[_vm._v("Aviso de privacidad")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.getRoute('Terminos')}}},[_c('v-list-item-title',[_vm._v("Términos y condiciones")])],1)],1)],1),_c('v-menu',{attrs:{"rounded":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":""}},'v-btn',attrs,false),on),[_vm._v("Ayuda")])]}}],null,false,1278817324)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.getRoute('Ayuda')}}},[_c('v-list-item-title',[_vm._v("Preguntas Frecuentes")])],1)],1)],1),_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s('Siniestro')},on:{"click":function($event){return _vm.getRoute('Siniestro')}}}),_c('v-btn',{attrs:{"text":""},domProps:{"textContent":_vm._s('Contacto')},on:{"click":function($event){return _vm.getRoute('Contacto')}}})]:_vm._e(),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.aseguradoraMeta.has_telefono),expression:"aseguradoraMeta.has_telefono"}],attrs:{"text":""},on:{"click":_vm.tagManager}},[_c('span',[_vm._v("TELÉFONO: 5588730915")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }