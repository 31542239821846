<template>
    <v-row>
        <v-col cols="12">
            <v-card flat>
                <v-card-title class="text-h5 lighten-2">¿QUIENES SOMOS?</v-card-title>
                <v-card-text class="mt-4">
                    <p>Somos una plataforma digital que te ayudará a entender de una manera fácil y sencilla el
                    seguro de auto que mejor se adapta a tus necesidades.
                    Entendemos lo complicado que puede ser asegurar tu vehículo por lo que, te llevaremos de la
                    mano para contratar tu póliza de una forma rápida, confiable y personalizada.
                    A través de coberturas sencillas y transparentes, te daremos la tranquilidad de contar con una
                    protección hecha a tu medida y lo mejor, a un increíble costo.<br>
                    ¡Elige seguro, elige Segurify!</p>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
export default {
    name:"NosotrosView"
}
</script>

<style>

</style>