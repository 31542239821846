let moment = require("moment-timezone");

async function identificadorvehiculo(identicador) {
  try {
    return await window.axios.get(`v1/vehiculo/identificador/${identicador}`);
  } catch (exception) {
    console.error(exception);
  }
}
async function cotiza(
  modelo,
  marca,
  submarca,
  version,
  plan,
  aseguradora,
  identificador,
  forma_pago,
  usuarioJson,
  portal,
  campania,
  cotizacion_general,
  age,
  origen = "Organico",
  partner
) {
  console.log({ partner });
  let vehiculo = null;
  let identicadorHomologado = null;

  if (identificador.includes("-")) {
    identicadorHomologado = await identificadorvehiculo(`${identificador}`);
  } else {
    identicadorHomologado = await identificadorvehiculo(
      `${identificador}-${modelo}`
    );
  }

  console.log({ aseguradoracotiza: aseguradora });
  if (aseguradora == "aig" || aseguradora == "aigInter") {
    vehiculo = `${identificador}-${modelo}`;
    if (campania == "comparador" || campania == "interprotecciones") {
      vehiculo = identicadorHomologado.data.identificadorAig;
    }
  } else if (aseguradora == "gnp" || aseguradora == "gnpInter") {
    vehiculo = `${modelo}-${marca.id}-${submarca.id}-${version.id}`;
    if (campania == "comparador" || campania == "interprotecciones") {
      vehiculo = identicadorHomologado.data.identificadorGnp;
    }
  } else {
    vehiculo = identificador;
  }

  let iden = null;
  if (aseguradora == "aig" || aseguradora == "aigInter") {
    iden = `${identificador}-${modelo}`;
    if (campania == "comparador" || campania == "interprotecciones") {
      iden = identicadorHomologado.data.identificadorAig;
    }
  } else if (aseguradora == "gnp" || aseguradora == "gnpInter") {
    iden = `${modelo}-${marca.id}-${submarca.id}-${version.id}`;
    if (campania == "comparador" || campania == "interprotecciones") {
      iden = identicadorHomologado.data.identificadorGnp;
    }
  } else {
    iden = identificador;
  }

  console.log("vehiculo", vehiculo);
  var cotizacionReq = {
    vehiculo: vehiculo,
    "forma-pago": forma_pago,
    plan: plan,
    identificador: iden,
    edad: age,
    sexo: usuarioJson.sexo,
    codigoPostal: usuarioJson.cp,
    modelo: modelo,
    cotizacion_general: cotizacion_general,
    usuarioJson: usuarioJson,
    vehiculoJson: {
      generoConductor: usuarioJson.sexo,
      modelo: {
        id: modelo,
      },
      marca: marca,
      submarca: submarca,
      vehiculo: version,
    },
    portal: portal,
    campania: campania,
    origen: origen,
    ...(partner && { partner }),
  };
  console.log({ cotizacionReq });

  if (campania == "comparador") {
    cotizacionReq["comparador"] = true;
  }

  let { data } = await window.axios.post(
    `v1/${aseguradora}/cotiza/landing`,
    cotizacionReq
  );
  return data;
}

async function enviaLead(
  usuarioJson,
  modelo,
  marca,
  submarca,
  version,
  medio,
  cotizacion_general,
  origen,
  campaniaOrigen
) {
  let request = {
    nombre: usuarioJson.name,
    apellidoPaterno: usuarioJson.lastname,
    apellidoMaterno: usuarioJson.lastname2,
    telefono: usuarioJson.phone,
    fechaContratacion: "Inmediato",
    correo: usuarioJson.email,
    sexo: usuarioJson.sexo,
    fechaNacimiento:
      usuarioJson.birthYear +
      "-" +
      usuarioJson.birthMonth +
      "-" +
      usuarioJson.birthDay,
    cp: usuarioJson.cp,
    marca: marca.marca,
    submarca: submarca.id,
    version: version.nombre,
    modelo: typeof modelo === "object" ? modelo.id : modelo,
    medio: medio,
    cotizacionGeneral: cotizacion_general,
    origen,
    campaniaOrigen,
  };
  await window.axios.post(`/v1/lead`, request);
}

async function enviarLeadIndividual(
  usuarioJson,
  modelo,
  marca,
  submarca,
  version,
  aseguradora,
  cotizacion,
  id
) {
  let request = {
    nombre: usuarioJson.name,
    apellido: `${usuarioJson.lastname} ${usuarioJson.lastname2}`,
    aseguradora: aseguradora,
    codigoPostal: usuarioJson.cp,
    correo: usuarioJson.email,
    cotizacion: id,
    fechaEstimadaContratacion: "Inmediato",
    fechaNacimiento: `${usuarioJson.birthDay}-${usuarioJson.birthMonth}-${usuarioJson.birthYear}`,
    fecha_lead: cotizacion.fecha.split(" ")[0],
    genero: usuarioJson.sexo,
    hora_lead: cotizacion.fecha.split(" ")[1],
    marca: marca.id,
    modelo: modelo,
    submarca: submarca.id,
    telefono: usuarioJson.phone,
    version: version.nombre,
  };
  await window.axios.post(`/v1/lead/landing`, request);
}
async function enviarLeadComparador(
  usuarioJson,
  modelo,
  marca,
  submarca,
  version,
  aseguradora,
  cotizacion,
  id,
  plan,
  partner
) {
  let request = {
    nombre: usuarioJson.name,
    apellido: `${usuarioJson.lastname} ${usuarioJson.lastname2}`,
    aseguradora: aseguradora,
    codigoPostal: usuarioJson.cp,
    correo: usuarioJson.email,
    cotizacion: id,
    fechaEstimadaContratacion: "Inmediato",
    fechaNacimiento: `${usuarioJson.birthDay}-${usuarioJson.birthMonth}-${usuarioJson.birthYear}`,
    fecha_lead: cotizacion.fecha.split(" ")[0],
    genero: usuarioJson.sexo,
    hora_lead: cotizacion.fecha.split(" ")[1],
    marca: marca.id,
    modelo: modelo,
    submarca: submarca.id,
    telefono: usuarioJson.phone,
    version: version.nombre,
    partner: cotizacion.partner || partner,
    plan: plan,
    ...(usuarioJson.idCliente && { idCliente: usuarioJson.idCliente }),
  };
  await window.axios.post(`/v1/lead/comparador`, request);
}
async function envioSinCotizar(
  usuarioJson,
  aseguradora,
  modelo,
  marca,
  submarca,
  version,
  plan
) {
  let format2 = moment(new Date());
  let local = moment(format2).tz("America/Mexico_City");
  let request = {
    nombre: usuarioJson.name,
    apellido: usuarioJson.lastname,
    telefono: usuarioJson.phone,
    aseguradora: aseguradora,
    fechaEstimadaContratacion: "Inmediato",
    correo: usuarioJson.email,
    genero: usuarioJson.sexo,
    fechaNacimiento:
      usuarioJson.birthYear +
      "-" +
      usuarioJson.birthMonth +
      "-" +
      usuarioJson.birthDay,
    codigoPostal: usuarioJson.cp,
    marca: marca.id,
    submarca: submarca.id,
    version: version.nombre,
    modelo: modelo.id,
    plan: plan,
    fecha_lead: local.format("YYYY-MM-DD"),
    hora_lead: local.format("HH:mm"),
  };
  let { data } = await window.axios.post(`/v1/lead/sin-cotizacion`, request);
  return data;
}
export {
  cotiza,
  enviaLead,
  enviarLeadIndividual,
  enviarLeadComparador,
  envioSinCotizar,
};
