<template>
  <body >
    <div class="header-content">
      <div class="topbar">
        <div class="myleft">
          <img
            alt="Seguros Segurify - Cotizador de Seguros de Auto"
            class="logo"
            src="@/assets/img/gnp_new.svg"
          />
        </div>
        <div class="myright">
          <img
            alt="Seguros Segurify - Cotizador de Seguros de Auto"
            class="logo p-2"
            src="@/assets/img/segurify_logo_bco.png"
          />
        </div>
      </div>
      <div>
        <div class="tracto">
          <div class="center-topbar-info">
            <div class="card">
              <div class="card-body my-p-info">
                <p class="text-title">¡Protégete ya!</p>
                <p class="text-center">
                  Para Segurify lo más importante es tu tranquilidad, por ello
                  nos especializamos en poner a tu alcance la mejor protección,
                  en solo un par de clics.
                </p>
                <p class="text-center text-options">
                  ¿Cuáles son nuestros beneficios?
                </p>
                <p class="text-left myoptions">
                  - Respaldo de una de las mejores aseguradoras a nivel
                  nacional.<br />
                  - El mejor precio del mercado.<br />
                  - Cotización y emisión de tu póliza en máximo 2 horas.<br />
                  - Coberturas especiales para el tipo de tracto.<br />
                  - Ejecutivos especializados que te explicarán a detalle tu
                  protección.<br />
                  - Asesoría personalizada.<br />
                </p>
              </div>
            </div>
          </div>
          <div class="center-topbar mt-2">
            <div class="card">
              <div class="card-body">
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <form id="inputForm">
                    <!-- <div class="d-flex flex-row flex-wrap justify-content-around align-content-center " > -->
                    <div
                      class="text-center"
                      v-show="mySend == true && errors == null"
                    >
                      <h2 style="text-align: center; color: #000063">
                        ¡Registro enviado!
                      </h2>
                      <h2 style="text-align: center; color: #000063">
                        En breve nos comunicaremos contigo.
                      </h2>
                    </div>

                    <div
                      class="text-center"
                      v-show="mySend == true && errors != null"
                    >
                      <h4 style="text-align: center; color: #000063">
                        ¡No se pudo enviar el registro!
                      </h4>
                      <h4 style="text-align: center; color: red">
                        {{ errors }}
                      </h4>

                      <h4 style="text-align: center; color: #000063">
                        Favor de mandar un correo a
                        <strong>soporteti@segurify.com </strong>
                      </h4>
                      <h4 style="text-align: center">
                        ¡Disculpe los inconvenientes!
                      </h4>
                    </div>
                    <div class="form-fields" v-show="mySend == false">
                      <div class="myform-custom pl-2 pr-2">
                        <h5
                          class="card-title"
                          style="
                            text-align: center;
                            font-size: 30px;
                            color: #6a6a6a;
                          "
                        >
                          Registrate por favor, en breve te llamaremos
                        </h5>

                        <ValidationObserver
                          ref="observer2"
                          v-slot="{ invalid2 }"
                        >
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="nombre"
                            ref="nombre"
                            rules="required|min:4"
                          >
                            <v-form id="input-group-nombre">
                              <v-text-field
                                id="nombre"
                                class="pl-2"
                                label="Registra tu nombre"
                                v-model="form.nombre"
                                placeholder="Juan"
                                aria-describedby="name-help-block"
                              />
                              <span class="input-invalid-message">
                                {{ errors[0] }}
                              </span>
                            </v-form>
                          </ValidationProvider>

                          <ValidationProvider
                            v-slot="{ errors }"
                            name="telefono"
                            ref="telefono"
                            rules="required"
                          >
                            <v-form id="input-group-telefono">
                              <v-text-field
                                id="telefono"
                                class="pl-2"
                                v-model="form.telefono"
                                label="Registra tu teléfono"
                                placeholder="5501526398"
                                type="text"
                                v-mask="'(##)########'"
                                aria-describedby="telefono-help-block"
                              />
                              <span class="input-invalid-message">
                                {{ errors[0] }}
                              </span>
                            </v-form>
                          </ValidationProvider>
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="correo"
                            ref="correo"
                            rules="required|email|min:10"
                          >
                            <v-form id="input-group-correo">
                              <v-text-field
                                id="correo"
                                class="pl-2"
                                v-model="form.correo"
                                label="Registra tu correo"
                                placeholder="juan@gmail.com"
                                aria-describedby="email-help-block"
                              />
                              <span class="input-invalid-message">
                                {{ errors[0] }}
                              </span>
                            </v-form>
                          </ValidationProvider>

                          <h5
                            class="card-title"
                            style="
                              text-align: center;
                              font-size: 20px;
                              color: #6a6a6a;
                            "
                          >
                            ¿Cuántos tractos deseas asegurar?
                          </h5>
                          <div class="text-center">
                            <ValidationProvider
                              v-slot="{ errors }"
                              name="cuantos"
                              ref="cuantos"
                              rules="required"
                            >
                              <v-radio-group v-model="form.selected">
                                <v-radio
                                  v-for="option in optionTractos"
                                  :key="option"
                                  :label="`${option.name}`"
                                  :value="option.item"
                                ></v-radio>
                              </v-radio-group>
                              <span class="input-invalid-message">
                                {{ errors[0] }}
                              </span>
                            </ValidationProvider>
                          </div>
                          <div class="col mt-2">
                            <v-btn
                              v-show="
                                form.selected != 'Camion Individual' ||
                                form.selected == null
                              "
                              @click="sendLead"
                              :disabled="invalid2"
                              color="rgb(19, 59, 125)"
                              style="
                                width: 100%;
                                margin-left: auto;
                                margin-right: auto;
                              "
                              dark
                              >Enviar</v-btn
                            >
                          </div>
                        </ValidationObserver>
                      </div>
                      <div
                        class="myform-custom pl-2 pr-2"
                        v-show="form.selected == 'Camion Individual'"
                      >
                        <h5
                          class="card-title"
                          style="
                            text-align: center;
                            font-size: 30px;
                            color: #6a6a6a;
                          "
                        >
                          Información del vehículo
                        </h5>

                        <ValidationProvider
                          v-slot="{ errors }"
                          name="modelo"
                          ref="modelo"
                          rules="required"
                        >
                          <v-form id="input-group-modelo">
                            <v-select
                              id="modelo"
                              class="pl-2"
                              v-model="form.modelo"
                              label="Elige un modelo"
                              :items="anios"
                              aria-describedby="modelo-help-block"
                            />
                            <span class="input-invalid-message">
                              {{ errors[0] }}
                            </span>
                          </v-form>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          name="brand"
                          ref="brand"
                          rules="required"
                        >
                          <v-form id="input-group-brand">
                            <v-select
                              id="brand"
                              class="pl-2"
                              v-model="form.marca"
                              label="Elige una marca"
                              :items="marcas"
                              aria-describedby="brand-help-block"
                            />
                            <span class="input-invalid-message">
                              {{ errors[0] }}
                            </span>
                          </v-form>
                        </ValidationProvider>

                        <ValidationProvider
                          v-slot="{ errors }"
                          name="version"
                          ref="version"
                          rules="required"
                        >
                          <v-form id="input-group-version">
                            <v-select
                              id="version"
                              class="pl-2"
                              label="Elige una versión"
                              v-model="form.version"
                              :items="versiones"
                              aria-describedby="version-help-block"
                            />
                            <span class="input-invalid-message">
                              {{ errors[0] }}
                            </span>
                          </v-form>
                        </ValidationProvider>

                        <div class="col">
                          <v-btn
                            @click="sendLead"
                            :disabled="invalid"
                            color="rgb(19, 59, 125)"
                            style="
                              width: 100%;
                              margin-left: auto;
                              margin-right: auto;
                            "
                            dark
                            >Enviar</v-btn
                          >
                        </div>
                      </div>
                    </div>
                    <!-- </div> -->
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bottombar mt-2">
        <footer>
          <p class="footer-txt">
            {{ new Date().getFullYear() }}. Agente autorizado. Consulta nuestros
            <a
              class="enlaceA"
              style="color: white !important"
              v-on:click="showTermsModal"
              >Términos y condiciones
            </a>
            y nuestro
            <a
              class="enlaceA"
              style="color: white !important"
              v-on:click="showPrivacyModal"
              >Aviso de privacidad</a
            >
          </p>
        </footer>
      </div>
      <modal name="termsModal" height="auto" width="80%" :scrollable="true">
        <div style="padding: 20px; margin-top: 10px">
          <button
            @click="$modal.hide('termsModal')"
            style="
              text-align: right;
              position: absolute;
              right: 10px;
              background: none;
              border: none;
            "
          >
            ❌
          </button>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
              padding: 0pt 0pt 14pt 0pt;
            "
          >
            <span
              style="
                font-size: 24pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >T&eacute;rminos y Condiciones</span
            >
          </p>
          <p
            dir="ltr"
            style="line-height: 1.2; margin-top: 0pt; margin-bottom: 0pt"
          >
            <br />
          </p>
          <hr />
          <p><br /></p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 700;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >T&eacute;rminos Generales</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Segurify de M&eacute;xico, Agente de Seguros y de Fianzas S.A de
              C.V. (en lo sucesivo &ldquo;Segurify&rdquo;) requiere que todos
              los visitantes en lo sucesivo &quot;Usuario&quot; a su (s) sitio
              (s), se adhieran a las siguientes reglas y condiciones, y a
              cualquier otro contrato de servicio, seg&uacute;n sea el caso, que
              sean requeridos a Segurify, para servicios o productos
              espec&iacute;ficos en los sucesivo &quot;Contratos de
              Servicio&quot;. Por el hecho de tener acceso al sitio, el Usuario
              expresa su aceptaci&oacute;n y acuerdo con estos t&eacute;rminos y
              condiciones, as&iacute; como con cada modificaci&oacute;n a los
              mismos que haga Segurify de momento a momento. Debido a la
              naturaleza de apertura de Internet y del Sitio, es necesario
              cambiar y modificar constantemente el contenido, estructura y
              servicios que se ofrecen. Al acceder al Sitio, el Usuario entiende
              y acepta que Segurify tendr&aacute; en cualquier momento el
              derecho de realizar dichas modificaciones sin previa
              notificaci&oacute;n. Por consiguiente, el Usuario acepta que es su
              obligaci&oacute;n revisar peri&oacute;dicamente este &quot;Aviso
              Legal&quot; al cual puede acceder en la parte inferior de cada
              p&aacute;gina del Sitio. Segurify no garantiza que los Productos,
              Materiales o Servicios disponibles a trav&eacute;s de este Sitio
              sean legales, apropiados o disponibles para uso de ubicaciones
              fuera del territorio de los Estados Unidos Mexicanos y el tener
              acceso a ellos desde territorios donde los mismos o su contenido
              es ilegal o prohibido. Cualquier Usuario que opte por acceder a
              este Sitio desde otras ubicaciones lo hace por iniciativa propia y
              es responsable del cumplimiento de leyes locales. Este Aviso
              Legal, sus modificaciones peri&oacute;dicas, una vez incorporadas
              al sitio, constituyen las reglas generales de uso del sitio, en lo
              sucesivo las &quot;Reglas del Sitio&quot;. Segurify le informa que
              si bien hace de buena fe su mejor esfuerzo a fin de que la calidad
              y el contenido que se proporciona mediante el sitio, el contenido
              al que es posible tener acceso a trav&eacute;s del Internet
              as&iacute; como aquel que se puede encontrar en cualquier enlace
              (link), p&aacute;gina web, etc., no es ni puede ser monitoreado,
              evaluado ni controlado por Segurify dicho contenido y direcciones
              disponibles cambian constantemente, por lo que desde este momento,
              el Usuario libera de manera absoluta y sin limitaci&oacute;n
              alguna a Segurify de cualquier tipo de responsabilidad, civil,
              penal, administrativa, como de los da&ntilde;os (patrimoniales o
              morales), perjuicios o cualquiera otra que pudiera emanar del uso
              de los Servicios y de la informaci&oacute;n disponible mediante el
              mismo.</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /><br
            /></span>
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >2. Servicios, Materiales y Productos</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Segurify puede proporcionar y/o poner a disposici&oacute;n del
              Usuarios diversos servicios financieros de seguros y de
              informaci&oacute;n, consistentes principalmente en ofertas para la
              contrataci&oacute;n de p&oacute;lizas de seguros de auto de
              distintas compa&ntilde;ias de seguros que contiene cada una de
              ellas distintas condiciones generales de contrataci&oacute;n,
              coberturas y precios, siendo ofertas ajenas al control de
              Segurify, en lo sucesivo los &quot;Servicios&quot; y otros
              Materiales y Productos e informaci&oacute;n relacionada disponible
              a trav&eacute;s del sitio, o por enlaces con otros sitios de
              Internet, ya sea directa o indirectamente por diversos proveedores
              ajenos a Segurify en lo sucesivo los &quot;Sitios de
              Terceros&quot;. Sin embargo, Segurify no puede garantizar el
              servicio, los Productos o los Materiales. El Usuarios entiende y
              acepta que pueden darse retrasos, omisiones o imprecisiones en
              cualquier Producto, Material o Servicio, mismos que de ninguna
              manera pueden ser considerados responsabilidad de Segurify</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >3. Responsabilidad</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Segurify, no es responsable y no controla previamente, aprueba,
              ni hace propios los servicios, informaci&oacute;n, datos,
              archivos, productos y cualquier clase de material existente en los
              sitios enlazados de las p&aacute;ginas de Internet. El Usuario,
              por tanto, debe extremar prudencia en la valoraci&oacute;n y
              utilizaci&oacute;n de los servicios, informaci&oacute;n, datos,
              archivos, productos y cualquier clase de material existente en los
              sitios enlazados. Segurify, no garantiza ni asume responsabilidad
              alguna por los da&ntilde;os y perjuicios de toda clase que puedan
              causarse por: (i) el funcionamiento, disponibilidad, accesibilidad
              o continuidad de los sitios enlazados; (ii) el mantenimiento de
              los servicios, informaci&oacute;n, datos, archivos, productos y
              cualquier clase de material existente en los sitios enlazados;
              (iii) las obligaciones y ofertas existentes en los sitios
              enlazados. Adicionalmente, el Usuario reconoce y acepta desde
              ahora que bajo su estricta responsabilidad hace uso de Internet y
              en general de medios de comunicaci&oacute;n electr&oacute;nicos y
              teleinform&aacute;ticos, de modo que en consecuencia, el Usuario
              libera expresamente a Segurify a sus funcionarios y directivos, de
              cualquier responsabilidad civil o penal derivada del uso de dichos
              medios de comunicaci&oacute;n. Segurify, no ser&aacute;n en
              ning&uacute;n caso responsable frente al Usuario o terceros, por
              da&ntilde;os y/o perjuicios que se relacionen con el uso o
              imposibilidad de uso de los servicios del sitio, ya sea que
              &eacute;stos deriven o no del incumplimiento a las instrucciones
              recibidas del Usuario, cuando la falta de cumplimiento se deba a
              un caso fortuito o de fuerza mayor, por fallas en el
              funcionamiento de sistemas de c&oacute;mputo o interrupci&oacute;n
              en los sistemas de comunicaci&oacute;n o alg&uacute;n
              acontecimiento similar, fuera del control de Segurify. Asimismo,
              el Usuario manifiesta bajo formal protesta de decir verdad que,
              los datos que le fueron solicitados en el sitio al momento de la
              contrataci&oacute;n de una p&oacute;liza de seguro de auto son
              correctos y corresponden fielmente con la realidad, aceptando que
              en caso de que no lo fuera as&iacute; y hubieren sido
              proporcionados datos incompletos o inexactos y por ende el
              Servicio contratado es negado por dicha causa por alguna de las
              compa&ntilde;&iacute;as de seguros que se anuncian en el sitio, es
              responsabilidad &uacute;nica y exclusivamente del Usuario.</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >4. Limitaci&oacute;n de Garant&iacute;as</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >El Usuario acepta que Segurify, no tendr&aacute; responsabilidad
              o contingencia alguna, de la precisi&oacute;n y puntualidad de los
              servicios o productos, o de la previsi&oacute;n, puntualidad,
              contenido o secuencia correcta de los materiales o de cualquier
              decisi&oacute;n o acto realizado por el usuario confi&aacute;ndose
              en los productos, materiales o servicio, por la
              interrupci&oacute;n o demora de cualquier informaci&oacute;n,
              material o cualquier otro aspecto de los servicios. Los servicios
              y todos los productos, materiales se proporcionan &quot;como
              est&aacute;n&quot;. No se garantiza que cualquiera servicio,
              productos, materiales y otros contenidos disponibles en o a
              trav&eacute;s del este sitio, o el sistema de computaci&oacute;n
              de Segurify cumplir&aacute; con los fines o necesidades
              particulares del usuario. No existen garant&iacute;as
              impl&iacute;citas de comercialidad o calidad, condiciones para un
              uso particular, o precisi&oacute;n del contenido
              inform&aacute;tico, y no existe ninguna otra garant&iacute;a de
              cualquier tipo, expresa o impl&iacute;cita, respecto a los
              productos, materiales o servicios, o cualquier otro aspecto de los
              servicios (incluyendo sin estar limitado a, acceso de
              informaci&oacute;n y orden de ejecuci&oacute;n) aun si Segurify
              han sido informado o de alguna a otra forma tiene conocimiento de
              la posibilidad de tales da&ntilde;os, incluyendo sin
              limitaci&oacute;n, responsabilidad asociada con cualquier
              c&oacute;digo de computadora, que pudiera infectar el equipo
              computacional o software relacionado del Usuario.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >5. Cambios a las Reglas del Sitio</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Segurify, se reserva el derecho a revisar el Aviso Legal en
              cualquier tiempo, as&iacute; como para realizar modificaciones en
              dicho aviso y adaptarlo a novedades legislativas,
              jurisprudenciales, as&iacute; como a pr&aacute;cticas de mercado.
              Asi mismo, el Usuario se considerar&aacute; enterado y obligado
              por los cambios al Aviso Legal al momento en que el Usuario
              ingrese al sitio y haga uso de cualquier material, producto o
              servicio. Dicho uso o acceso al sitio por parte del usuario
              constituir&aacute; la aceptaci&oacute;n y acuerdo a los cambios
              del Aviso Legal, independientemente de lo dispuesto en el Contrato
              de Servicios.</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >6. Marcas y Propiedad Intelectual</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >La marca &ldquo;Segurify&rdquo; y logo que aparece en el sitio es
              una marca debidamente autorizada por su titular para uso y
              explotaci&oacute;n comercial de Segurify de M&eacute;xico, Agente
              de Seguros y de Fianzas S.A de C.V. Todas las dem&aacute;s marcas
              y/o logos que aparece en el sitio se entienden propiedad de
              terceros, seg&uacute;n se indique. Copyright en las
              im&aacute;genes, textos, pantallas y sitios de Internet que
              aparezcan o se relacionen con este sitio son propiedad de
              Segurify, como se indica. Se puede copiar la informaci&oacute;n y
              material de los sitios para el uso personal o educaStivo, siempre
              y cuando cada copia incluya una leyenda de Copyright, marcas
              registradas o derechos reservados cuando se realice la
              reproducci&oacute;n. Sin embargo, la informaci&oacute;n y material
              de los sitios no podr&aacute;n ser copiados, mostrados,
              distribuidos, impresos, dados en cualquier tipo de licencia,
              modificados, publicados, reproducidos, re usados, vendidos,
              transmitidos, usados para crear un trabajo derivado, o usados para
              fines p&uacute;blicos o comerciales, a excepci&oacute;n de que
              cumpla con los t&eacute;rminos y condiciones mostrados en el sitio
              y se tenga el consentimiento escrito de Segurify y/o de las
              empresas titulares de dichas marcas. Los actos de
              reproducci&oacute;n, env&iacute;o, retransmisi&oacute;n,
              difusi&oacute;n, venta, distribuci&oacute;n, publicaci&oacute;n o
              transmisi&oacute;n de dicho contenido, no transferir&aacute;n al
              Usuario de los servicios de nuestros portales, titularidad alguna
              sobre cualquier software o material en este sitio.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >7. Cookies</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Segurify, se reserva el derecho de crear en la computadora del
              Usuario, un archivo cookie u otro de igual prop&oacute;sito, mismo
              que almacenar&aacute; informaci&oacute;n relacionada con el uso
              del sitio y que permitir&aacute; prestar los servicios
              relacionados con nuestros portales en forma m&aacute;s sencilla
              para el propio Usuario.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >8. Autorizaci&oacute;n a ligas de p&aacute;ginas.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >A trav&eacute;s de este sitio el Usuario podr&aacute; tener
              enlaces a otros sitios, en los cuales,. Segurify, hace su mejor
              esfuerzo para asegurarse que el contenido de dichos sitios cumpla
              con los est&aacute;ndares establecidos para esta p&aacute;gina.
              Los enlaces, sus direcciones y contenidos pueden cambiar
              constantemente y Segurify no ser&aacute;n responsables de la
              disponibilidad o funcionamiento del contenido de estos sitios.
              Esta p&aacute;gina y los enlaces a otros sitios no podr&aacute;n
              ser objeto de ligas o enlaces a sitios de terceros sin
              autorizaci&oacute;n previa y por escrito de Segurify</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >9. Autorizaci&oacute;n para usar la informaci&oacute;n</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Al utilizar esta p&aacute;gina, el Usuario autoriza a Segurify, a
              hacer uso de la informaci&oacute;n personal proporcionada
              libremente por el propio Usuario, para cualquier fin legal,
              incluido, sin limitaci&oacute;n alguna, para ser usada para
              contactar al Usuario v&iacute;a tel&eacute;fono y/o correo
              elect&oacute;nico con el objetivo de darle a conocer
              informaci&oacute;n relacionada con los Servicios que el propio
              Usuario manifest&oacute; tener inter&eacute;s en contratar con
              Segurify.</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >10. Fin de la informaci&oacute;n</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >No se deber&aacute; entender que la informaci&oacute;n financiera
              y de cualquier otra naturaleza que aparezca en este sitio tiene el
              prop&oacute;sito de asesorar a los Usuarios o que Segurify, son
              responsables de la emisi&oacute;n de dicha informaci&oacute;n, por
              lo que se advierte al Usuario que podr&aacute; existir demora,
              inexactitud u omisi&oacute;n en la informaci&oacute;n ah&iacute;
              contenida y que la misma no deber&aacute; ser considerada como
              oferta o recomendaci&oacute;n al Usuario para la
              realizaci&oacute;n de operaciones espec&iacute;ficas. Segurify no
              garantiza la exactitud o integridad de la informaci&oacute;n que
              se proporciona a trav&eacute;s de los servicios de sus portales.
              El contenido de este sitio p&aacute;gina estar&aacute; sujeto a
              cambios sin previo aviso.</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >11. Ley y jurisdicci&oacute;n</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >El Usuario al hacer uso de las p&aacute;ginas de Internet de
              Segurify, acepta de manera expresa someterse en caso de cualquier
              controversia, a la jurisdicci&oacute;n de los Tribunales de la
              Ciudad de M&eacute;xico, M&eacute;xico, as&iacute; como a las
              leyes aplicables para el caso concreto vigentes en dicha Entidad,
              renunciando tambi&eacute;n expresamente a cualquier otra que por
              motivo de su nacionalidad o domicilio pudiera corresponder.</span
            >
          </p>
          <p><br /></p>
          <p><br /></p>
        </div>
      </modal>

      <modal name="privacyModal" height="auto" width="80%" :scrollable="true">
        <div style="padding: 20px; margin-top: 10px">
          <button
            @click="$modal.hide('privacyModal')"
            style="
              text-align: right;
              position: absolute;
              right: 10px;
              background: none;
              border: none;
            "
          >
            ❌
          </button>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
              padding: 0pt 0pt 14pt 0pt;
            "
          >
            <span
              style="
                font-size: 24pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Aviso de Privacidad</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <br />
          </p>
          <hr />
          <p><br /></p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Segurify de M&eacute;xico, Agente de Seguros y de Fianzas S.A de
              C.V. (en lo sucesivo &ldquo;Segurify&rdquo;), con domicilio en Av.
              Miguel &Aacute;ngel de Quevedo 722 Colonia Barrio la
              Concepci&oacute;n C.P. 04020 Alcald&iacute;a Coyoac&aacute;n,
              Ciudad de M&eacute;xico es el responsable de recabar sus datos
              personales, del uso que se le d&eacute; a los mismos y de su
              protecci&oacute;n, esto en cumplimiento a lo dispuesto en la Ley
              Federal de Protecci&oacute;n de Datos Personales en
              Posesi&oacute;n de los Particulares, pone a su disposici&oacute;n
              el presente Aviso de Privacidad.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Datos personales que podr&aacute;n ser recabados y tratados para
              llevar a cabo las finalidades descritas en el presente Aviso de
              Privacidad, sus datos personales que podr&aacute;n ser recabados y
              sujetos a tratamiento son: 1) Nombre; 2) Domicilio; 3)
              Tel&eacute;fono fijo y/o celular; 4) Correo electr&oacute;nico; 5)
              Edad; 6) G&eacute;nero; 7) Copia de identificaci&oacute;n oficial;
              8) Registro Federal de Contribuyentes; y 9) CURP.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Uso de los Datos Personales</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Los Datos Personales que usted proporcione de manera voluntaria a
              Segurify podr&aacute;n ser usados por nosotros: (i) para
              proporcionarle nuestros servicios financieros, (ii) para
              proporcionarle informaci&oacute;n relativa a nuestros servicios de
              seguros y Fianzas, (iii) para efectos de mercadotecnia internos, y
              (iv) para mantener vigente y existente nuestra relaci&oacute;n con
              usted y/o dar cumplimiento a los compromisos adquiridos. Segurify.
              podr&aacute; adem&aacute;s hacer uso de sus Datos Personales para
              fines hist&oacute;ricos y estad&iacute;sticos, en cuyos casos no
              se requerir&aacute; Aviso de Privacidad alguno.</span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Limitaci&oacute;n al Uso de Datos Personales:</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Usted podr&aacute; limitar el uso y/o divulgaci&oacute;n de sus
              Datos Personales, incluyendo sin limitar, su negativa a recibir
              comunicados y promociones por parte de Segurify mediante el
              procedimiento establecido m&aacute;s adelante bajo el rubro de
              &ldquo;Acceso, Actualizaci&oacute;n, Rectificaci&oacute;n,
              Cancelaci&oacute;n de Datos Personales, Limitaci&oacute;n de Uso
              y/o Revocaci&oacute;n del Consentimiento&rdquo;. En caso de
              otorgar su consentimiento, usted lo podr&aacute; revocar mediante
              el procedimiento establecido m&aacute;s adelante bajo el rubro
              &ldquo;Acceso, Actualizaci&oacute;n, Rectificaci&oacute;n y
              Cancelaci&oacute;n de Datos Personales, Limitaci&oacute;n de Uso
              y/o Revocaci&oacute;n del Consentimiento&rdquo;.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Cambios a Nuestras Pol&iacute;ticas de Privacidad:</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >El presente Aviso de Privacidad podr&aacute; ser modificado
              peri&oacute;dicamente y sin previo aviso para reflejar los cambios
              en las pr&aacute;cticas de uso y recopilaci&oacute;n de
              informaci&oacute;n de Segurify , el cual se pondr&aacute; a su
              disposici&oacute;n en nuestras oficinas, o mediante medios
              electr&oacute;nicos. En caso de que la modificaci&oacute;n al
              presente Aviso de Privacidad implique: (i) un cambio de identidad
              de Segurify; (ii) requiramos recabar Datos Sensibles,
              patrimoniales o financieros adicionales; (iii) cambien las
              finalidades del uso de los Datos Personales; y/o (iv) se
              modifiquen las condiciones de pagos y/o transferencias, pondremos
              a su disposici&oacute;n un nuevo Aviso de Privacidad. Lo anterior,
              con la finalidad que usted siempre tenga conocimiento de nuestras
              pol&iacute;ticas vigentes.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Acceso, Actualizaci&oacute;n, Rectificaci&oacute;n,
              Cancelaci&oacute;n de Datos Personales, Limitaci&oacute;n de Uso
              y/o Revocaci&oacute;n del Consentimiento:</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Usted podr&aacute; tener acceso, actualizar, rectificar y
              cancelar sus Datos Personales, limitar su uso y/o
              divulgaci&oacute;n, y/o revocar su consentimiento, ya sea
              marc&aacute;ndonos al tel&eacute;fono (55) 88730915, enviando un
              correo electr&oacute;nico a atencionaclientes@segurify.com o bien
              escribi&eacute;ndonos a Av. Miguel &Aacute;ngel de Quevedo 722
              Colonia Barrio la Concepci&oacute;n C.P. 04020 Alcald&iacute;a
              Coyoac&aacute;n, Ciudad de M&eacute;xico, proporcionando la
              siguiente informaci&oacute;n: (i) nombre del titular; (ii)
              domicilio y/o correo electr&oacute;nico del titular para
              comunicarle la respuesta, bajo el entendido que en caso de
              solicitar informaci&oacute;n de Datos Personales, la misma se le
              har&aacute; llegar al domicilio o al correo electr&oacute;nico que
              nos haya usted indicado; (iii) los documentos que acrediten la
              identidad del titular y, en su caso, la representaci&oacute;n
              legal del titular; (iv) la descripci&oacute;n de los Datos
              Personales de los que se busca tener acceso, actualizar,
              rectificar o cancelar, limitar su uso y/o divulgaci&oacute;n y/o
              limitar su consentimiento; (v) cualquier otro elemento que
              facilite la localizaci&oacute;n de los Datos Personales.</span
            >
          </p>
          <p
            dir="ltr"
            style="
              line-height: 1.2;
              text-align: justify;
              background-color: #f8fafc;
              margin-top: 0pt;
              margin-bottom: 0pt;
            "
          >
            <span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              ><br /></span
            ><span
              style="
                font-size: 11pt;
                font-family: Arial;
                color: #808080;
                background-color: transparent;
                font-weight: 400;
                font-style: normal;
                font-variant: normal;
                text-decoration: none;
                vertical-align: baseline;
                white-space: pre;
                white-space: pre-wrap;
              "
              >Adicionalmente, en caso de solicitudes de rectificaci&oacute;n de
              Datos Personales, usted deber&aacute; indicar las modificaciones a
              realizarse y aportar la documentaci&oacute;n que sustente su
              petici&oacute;n. Para la cancelaci&oacute;n de sus Datos
              Personales, ser&aacute; necesaria la terminaci&oacute;n de nuestra
              relaci&oacute;n con usted, en virtud de que los mismos son
              necesarios para la prestaci&oacute;n de nuestros servicios.
              Segurify le dar&aacute; respuesta de la determinaci&oacute;n
              adoptada en relaci&oacute;n con su solicitud en el domicilio o
              correo electr&oacute;nico que al efecto haya se&ntilde;alado, en
              un plazo m&aacute;ximo de 20 (veinte) d&iacute;as naturales
              contados a partir de la fecha en que se recibi&oacute; la
              solicitud, a efecto de que, si resulta procedente, se haga
              efectiva la misma dentro de los 15 (quince) d&iacute;as naturales
              siguientes a la fecha en que se le comunique la respuesta.
              Segurify se reserva el derecho de ampliar los plazos antes
              mencionados, por una sola vez, por un periodo igual, siempre y
              cuando as&iacute; lo justifiquen las circunstancias del caso. En
              caso de que ocurra una vulneraci&oacute;n a la seguridad de sus
              Datos Personales, que afecte de forma significativa sus derechos
              patrimoniales o morales, Segurify lo har&aacute; de su
              conocimiento lo m&aacute;s pronto posible v&iacute;a correo
              electr&oacute;nico, a fin de que usted pueda tomar las medidas
              necesarias correspondientes para la defensa de sus derechos.
              Recuerde que en t&eacute;rminos de la Ley Federal de
              Protecci&oacute;n de Datos Personales en Posesi&oacute;n de los
              Particulares a usted le puede asistir el derecho para solicitar el
              inicio del procedimiento de protecci&oacute;n de derechos de sus
              Datos Personales ante el Instituto Federal de Acceso a la
              Informaci&oacute;n y Protecci&oacute;n de Datos.</span
            >
          </p>
          <p><br /></p>
          <p><br /></p>
        </div>
      </modal>
    </div>
  </body>
</template>
   <script>
import json from "./tractos.json";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },

  data() {
    return {
      mySend: false,
      tractos: json,
      form: {
        nombre: null,
        apellido_paterno: null,
        apellido_materno: null,
        correo: null,
        telefono: null,
        modelo: null,
        marca: null,
        version: null,
        selected: null,
      },
      optionTractos: [
        { item: "Flotilla", name: "Flotilla" },
        { item: "Camion Individual", name: "Individual" },
      ],
      vehiculoShow: false,
      cargando: true,
      errors: null,
      response: null,
      // anios:[],
    };
  },
  async mounted() {
    //  let config = {
    //         headers: {
    //         "Authorization": "Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5",
    //         }
    //     }
    //     let vm=this;
    //      await this.$axios.$get("https://api.segurify.com/v1/vehiculo/"+"qualitas"+"/modelos",config)
    // .then(function(response){
    //     vm.anios=response;
    // });
  },
  computed: {
    anios() {
      var anios = [];
      this.tractos.forEach((element) => {
        if (!anios.includes(element.modelo)) {
          anios.push(element.modelo);
        }
      });
      return anios.sort().reverse();
    },
    marcas() {
      var marcas = [];
      if (this.form.modelo != null) {
        this.tractos.forEach((element) => {
          if (
            !marcas.includes(element.marca) &&
            element.modelo == this.form.modelo
          ) {
            marcas.push(element.marca);
          }
        });
      }
      return marcas;
    },
    versiones() {
      var versiones = [];
      if (this.form.modelo != null && this.form.marca != null) {
        this.tractos.forEach((element) => {
          if (
            !versiones.includes(element.tracto) &&
            element.modelo == this.form.modelo &&
            element.marca == this.form.marca
          ) {
            versiones.push(element.tracto);
          }
        });
      }
      return versiones;
    },
  },
  methods: {
    showTermsModal() {
      this.$modal.show("termsModal");
    },
    showPrivacyModal() {
      this.$modal.show("privacyModal");
    },
    validateField(field) {
      const provider = this.$refs[field];

      // Validate the field
      return provider.validate();
    },
    formatNumber(e) {
      var numbers = e.replace(/\D+/g, "");
      return String(numbers).substring(0, 10);
    },
    async sendLead() {
      const isValid2 = await this.$refs.observer2.validate();
      const isValid = await this.$refs.observer.validate();
      if (!isValid2) {
        // ABORT!!
      } else {
        let vm = this;
        // vm.mySend=true
        this.$gtag.event("enviar", {
          event_category: "click",
          event_label: "Tractos",
        });
        var telefonoAEnviar = vm.form.telefono.replace("(", "");
        telefonoAEnviar = telefonoAEnviar.replace(")", "");
        if (!isValid) {
          await this.axios
            .post("/v1/lead-tractos", {
              nombre: vm.form.nombre,
              tipo: vm.form.selected,
              correo: vm.form.correo,
              telefono: "52" + telefonoAEnviar,
              medio: "Landing Tractos",
              modelo: " ",
              marca: " ",
              version: " ",
            })
            .then(function (response) {
              vm.response = response;
              vm.errors = null;
              vm.mySend = true;
            })
            .catch(function (error) {
              vm.errors = error;
              vm.response = null;
              vm.mySend = true;
            });
        } else {
          await this.axios
            .post(
              "/v1/lead-tractos",
              {
                nombre: vm.form.nombre,
                tipo: vm.form.selected,
                correo: vm.form.correo,
                telefono: "52" + telefonoAEnviar,
                medio: "Landing Tractos",
                modelo: vm.form.modelo,
                marca: vm.form.marca,
                version: vm.form.version,
              }
              // {
              //     headers: {
              //         'content-type': 'application/json' ,
              //         "Authorization": "Bearer 8b06348c-2830-486c-bb7c-e0599ae750f5"
              //     }
              // }
            )
            .then(function (response) {
              vm.response = response;
              vm.errors = null;
              vm.mySend = true;
            })
            .catch(function (error) {
              vm.errors = error;
              vm.response = null;
              vm.mySend = true;
            });
        }
      }
    },
  },
};
</script>
   <style>
.input-invalid-message {
  color: red;
  font-size: 10px;
  font-weight: 700;
  /* background-color:black; */
  /* text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white; */
}
.theme--light.v-application {
  background: none;
}
html {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}
p {
  text-align: center;
}
.text-title {
  font-size: 25px;
  font-weight: 650;
}
.text-options {
  font-weight: 650;
}
.my-p-info {
  color: #000063;
  font-weight: 600;
  line-height: 25px;
}
.header-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: column;
}
.topbar {
  width: 100%;
  background-color: rgb(19, 59, 125);
  border-bottom: 8px solid rgb(255, 110, 0);
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
}
.center-topbar-info {
  min-width: 320px;
  max-width: 600px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.center-topbar {
  min-width: 320px;
  flex-wrap: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
.tracto {
  flex-wrap: wrap;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
  padding: 30px 20px;
}
.bottombar {
  height: auto;
  background-color: rgb(19, 59, 125);
}
.info {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  flex-direction: row;
}
.info-img {
  max-width: 450px;
}
.info-txt {
  max-width: 450px;
  color: white;
}
.logo-segurify {
  height: 18vh;
}
.logo {
  height: 18vh;
}
.mytitle {
  text-align: center;
  padding: 10px;
  color: white;
}
p.footer-txt {
  color: white;
  text-align: center;
  font-size: 11px !important;
}
.my-content-title {
  color: black;
  font-size: 30px;
}
.card {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 25px;
}
.form-fields {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: space-around;
  flex-direction: row;
}
.my-nav-title {
  color: white;
  font-size: 35px;
}
.myright {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  flex-direction: row;
}
.logo-text {
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  justify-content: flex-end;
  flex-direction: row;
}
@media screen and (max-width: 410px) {
  .center-topbar-info {
    max-width: 320px;
  }
  .center-topbar {
    max-width: 300px;
  }
  .myform-custom {
    width: 300px;
  }
  .logo-segurify {
    height: 12vh;
  }
  .logo {
    height: 12vh;
  }
  .logo-text {
    width: 100px;
  }
  .my-nav-title {
    font-size: 12px;
    line-height: 15px;
    text-align: right;
    margin-top: auto;
    margin-bottom: auto;
  }
  .info-img {
    max-width: 340px;
  }
  .info-txt {
    max-width: 340px;
    color: white;
  }
  p.myoptions {
    text-align: justify !important;
    text-justify: inter-word;
    padding-left: auto;
    padding-right: auto;
    font-size: 10px;
  }
  p.text-center {
    text-align: justify !important;
    text-justify: inter-word;
    font-size: 12px;
    line-height: 19px;
  }
  .form-fields {
    font-size: 15px !important;
  }
  .form-control {
    font-size: 12px !important;
  }
  .text-title {
    font-size: 15px !important;
  }
  .card-title {
    font-size: 15px !important;
  }
  .card-body {
    padding: 20px 20px !important;
  }
}
@media screen and (max-width: 630px) and (min-width: 411px) {
  .center-topbar-info {
    max-width: 400px;
  }
  .center-topbar {
    max-width: 400px;
  }
  .myform-custom {
    width: 400px;
  }
  .logo-segurify {
    height: 12vh;
  }
  .logo {
    height: 12vh;
  }
  .my-nav-title {
    font-size: 15px;
    line-height: 25px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .info-img {
    max-width: 340px;
  }
  .info-txt {
    max-width: 340px;
    color: white;
  }
  p.myoptions {
    text-align: justify !important;
    text-justify: inter-word;
    padding-left: auto;
    padding-right: auto;
    font-size: 15px;
  }
  p.text-center {
    text-align: justify !important;
    text-justify: inter-word;
    font-size: 15px;
  }
  .card-title {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 679px) and (min-width: 631px) {
  .center-topbar-info {
    max-width: 600px;
  }
  .center-topbar {
    max-width: 600px;
  }
  .myform-custom {
    width: 600px;
  }
  .logo-segurify {
    height: 10vh;
  }
  .logo {
    height: 10vh;
  }
  .logo-text {
    width: 400px;
  }
  .my-nav-title {
    font-size: 15px;
    line-height: 25px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .info-img {
    max-width: 340px;
  }
  .info-txt {
    max-width: 340px;
    color: white;
  }
  p.myoptions {
    text-align: justify !important;
    text-justify: inter-word;
    padding-left: auto;
    padding-right: auto;
    font-size: 12px;
  }
  p.text-center {
    text-align: justify !important;
    text-justify: inter-word;
    font-size: 15px;
  }
  .card-title {
    font-size: 15px !important;
  }
}
@media screen and (max-width: 800px) and (min-width: 700px) {
  .center-topbar-info {
    max-width: 600px;
  }
  .center-topbar {
    max-width: 600px;
  }
  .myform-custom {
    width: 600px;
  }
  .logo-segurify {
    height: 10vh;
  }
  .logo {
    height: 10vh;
  }
  .logo-text {
    width: 400px;
  }
  .my-nav-title {
    font-size: 15px;
    line-height: 25px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .info-img {
    max-width: 340px;
  }
  .info-txt {
    max-width: 340px;
    color: white;
  }
  p.myoptions {
    text-align: justify !important;
    text-justify: inter-word;
    padding-left: auto;
    padding-right: auto;
    font-size: 12px;
  }
  p.text-center {
    text-align: justify !important;
    text-justify: inter-word;
    font-size: 15px;
  }
  .card-title {
    font-size: 15px !important;
  }
}
@media screen and (min-width: 800px) {
  .center-topbar-info {
    max-width: 400px;
  }
  .logo-segurify {
    height: 10vh;
  }
  .logo {
    height: 90px;
  }
  .logo-text {
    width: 400px;
  }
  .my-nav-title {
    font-size: 18px;
    line-height: 25px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .info-img {
    max-width: 400px;
  }
  .info-txt {
    max-width: 400px;
    color: white;
  }
  p.myoptions {
    text-align: justify !important;
    text-justify: inter-word;
    padding-left: auto;
    padding-right: auto;
    font-size: 12px;
  }
  p.text-center {
    text-align: justify !important;
    text-justify: inter-word;
    font-size: 15px;
  }
  .card-title {
    font-size: 15px !important;
  }
  .text-title {
    font-size: 25px !important;
    text-align: center !important;
    font-weight: 650;
  }
}
.card {
  padding: 25px;
}
</style>
