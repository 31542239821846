<template>
    <v-card :loading="load" elevation="4" class="text-center" dense>
        <v-card-text>
            <div class="ribbon" v-if="plan == 'amplia'"><span>RECOMENDADO</span></div>

            <v-row>
                <v-col cols="12" md="12">
                    <div class="text-uppercase">
                        <h3>{{plan}}</h3>
                    </div>
                </v-col>
            </v-row>

            <template v-if="NoCotiza">
                <v-row class="mt-14">
                    <v-col cols="12" md="12">
                        <div class="text-uppercase">
                            <h2>NO DISPONIBLE</h2>
                        </div>
                    </v-col>
                </v-row>
            </template>

            <template v-else>
                <template v-if="cotizacion == null" >
                    <v-row dense>
                        <v-col cols="12" md="12">
                            <div>
                                <h3>Pago anual</h3>
                                <p class="precio">{{this.$formatMoney()}}</p>
                            </div>                
                        </v-col>
                    </v-row>
                </template>

                <template v-else>
                    <v-row dense>
                        <v-col cols="12" md="12">
                            <div>
                                <h3>Pago anual</h3>
                                <p class="precio">{{this.$formatMoney(cotizacion.total)}}</p>
                            </div>                
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="12">
                            <v-simple-table v-if="plan == 'amplia'">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Daños Materiales</td>
                                            <td>5%</td>
                                        </tr>

                                        <tr>
                                            <td>Robo total</td>
                                            <td>10%</td>
                                        </tr>

                                        <tr>
                                            <td>Responsabilidad civil LUC</td>
                                            <td>$3,000,000</td>
                                        </tr>

                                        <tr>
                                            <td>Extensión de RC</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Gastos médicos ocupantes</td>
                                            <td>$300,000.00 </td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Accidentes automovilísticos al conductor</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia jurídica</td>
                                            <td>AMPARADA</td>
                                        </tr >

                                        <tr v-if="showDetails">
                                            <td>Asistencia funeraria</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia en viajes</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>RC daños a terceros EUA</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Auto siempre / 5 días</td>
                                            <td>N/A</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-simple-table v-if="plan == 'amplia-plus'">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Daños Materiales</td>
                                            <td>5%</td>
                                        </tr>

                                        <tr>
                                            <td>Robo total</td>
                                            <td>10%</td>
                                        </tr>

                                        <tr>
                                            <td>Responsabilidad civil LUC</td>
                                            <td>$3,000,000 </td>
                                        </tr>

                                        <tr>
                                            <td>Extensión de RC</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Gastos médicos ocupantes</td>
                                            <td>$300,000.00 </td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Accidentes automovilísticos al conductor</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia jurídica</td>
                                            <td>AMPARADA</td>
                                        </tr >

                                        <tr v-if="showDetails">
                                            <td>Asistencia funeraria</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia en viajes</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>RC daños a terceros EUA</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Auto siempre / 5 días</td>
                                            <td>AMPARADA</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-simple-table v-if="plan == 'rc'">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Daños Materiales</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Robo total</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Responsabilidad civil LUC</td>
                                            <td>$3,000,000 </td>
                                        </tr>

                                        <tr>
                                            <td>Extensión de RC</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Gastos médicos ocupantes</td>
                                            <td>$300,000.00 </td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Accidentes automovilísticos al conductor</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia jurídica</td>
                                            <td>AMPARADA</td>
                                        </tr >

                                        <tr v-if="showDetails">
                                            <td>Asistencia funeraria</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia en viajes</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>RC daños a terceros EUA</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Auto siempre / 5 días</td>
                                            <td>N/A</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>

                            <v-simple-table v-if="plan == 'limitada'">
                                <template v-slot:default>
                                    <tbody>
                                        <tr>
                                            <td>Daños Materiales</td>
                                            <td>N/A</td>
                                        </tr>

                                        <tr>
                                            <td>Robo total</td>
                                            <td>10%</td>
                                        </tr>

                                        <tr>
                                            <td>Responsabilidad civil LUC</td>
                                            <td>$3,000,000 </td>
                                        </tr>

                                        <tr>
                                            <td>Extensión de RC</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Gastos médicos ocupantes</td>
                                            <td>$300,000.00 </td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Accidentes automovilísticos al conductor</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia jurídica</td>
                                            <td>AMPARADA</td>
                                        </tr >

                                        <tr v-if="showDetails">
                                            <td>Asistencia funeraria</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Asistencia en viajes</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>RC daños a terceros EUA</td>
                                            <td>AMPARADA</td>
                                        </tr>

                                        <tr v-if="showDetails">
                                            <td>Auto siempre / 5 días</td>
                                            <td>N/A</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </template>

                <v-row justify="center" dense>
                    <v-col cols="11">
                        <v-btn color="primary" block @click="show"> {{showDetails ? 'Ocultar': 'Ver detalles'}}</v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mapState } from 'vuex'
    import { cotiza } from '@/util/CotizaHelper.js';
    export default {
        props:["usuarioJson","vehiculo","marca","submarca","modelo", 'plan', 'origen'],
        data:()=>({
            load: true,
            cotizacion:null,
            loading: false,
            showDetails:false,
            NoCotiza: false
        }),
        computed: {
            ...mapState(['aseguradoraMeta', 'cotizacionGeneral'])
        },
        mounted(){
            this.cotizar();
        },
        methods:{
            show(){
                this.showDetails = !this.showDetails
            },
            async cotizar(){
                try{
                    this.load = true;
                    this.cotizacion = await cotiza(this.modelo, this.marca, this.submarca, this.vehiculo, this.plan, this.aseguradoraMeta.aseguradora, this.vehiculo.id, "92dabb35-e35b-4413-b29a-5d0e3fd69ba5",this.usuarioJson, this.aseguradoraMeta.portal, this.aseguradoraMeta.aseguradora, this.cotizacionGeneral, this.$getAge(this.usuarioJson.birthYear), this.origen);
                    if(this.cotizacion.status != 'FAILED'){
                        this.NoCotiza = false;
                        this.$store.commit('setCotizaciones', this.cotizacion);
                    }else{
                        this.NoCotiza = true;
                        this.$store.commit('setNoCotiza', null);
                        this.$store.commit('setCotizaciones', null);
                    }
                    this.load = false;
                }catch(exception){
                    console.error(exception)
                    this.load = false;
                    this.NoCotiza = true;
                    this.$store.commit('setCotizaciones', null);
                    this.$store.commit('setNoCotiza', null);
                }
            }
        }
    }
</script>

<style>
    .v-data-table__wrapper{
        overflow: hidden !important;
    }
</style>
