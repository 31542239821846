<template>
  <div class="mt-4">
    <v-row class="justify-space-between">
      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
        <v-btn elevation="2" block dark :color="plan == 'amplia' ? 'primary' : '#263238'" @click="plan = 'amplia'">Amplia
        </v-btn>
      </v-col>
      <v-col v-if="aseguradoraMeta.aseguradora != 'interprotecciones'" cols="12" sm="12" md="3" lg="3" xl="3">
        <v-btn elevation="2" block dark :color="plan == 'amplia-plus' ? 'primary' : '#263238'" @click="plan = 'amplia-plus'">
          Amplia Plus</v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
        <v-btn elevation="2" block dark :color="plan == 'rc' ? 'primary' : '#263238'" @click="plan = 'rc'">Responsabilidad
          Civil
        </v-btn>
      </v-col>
      <v-col cols="12" sm="12" md="3" lg="3" xl="3">
        <v-btn elevation="2" block dark :color="plan == 'limitada' ? 'primary' : '#263238'" @click="plan = 'limitada'">
          Limitada
        </v-btn>
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="12" sm="12" md="8" lg="8" xl="8">
        <v-card>
          <v-toolbar flat class="text-center" color="primary">
            <v-toolbar-title class="text-center white--text text-h5" tag="span" style="padding-left: 1%;">
              La mejor oferta para ti
            </v-toolbar-title>
          </v-toolbar>

          <v-card-text>
            <v-row v-if="loading" class="justify-center text-center">
              <v-col cols="12">
                <v-progress-circular :size="100" :width="7" color="primary" indeterminate></v-progress-circular>
              </v-col>
            </v-row>

            <v-row v-else justify="space-between" class="text-center">
              <template v-if="MejorCotizacion == null">
                <v-col cols="12">
                  <h2 class="text-center">No se encontro información de cotización</h2>
                </v-col>
              </template>

              <template v-else>
                <v-col cols="12" sm="12" md="6" lg="4" xl="4" class="d-flex align-center">
                  <v-img :src="MejorCotizacion.logo" alt="Seguros Segurify - Cotizador de Seguros de Auto" width="200"
                    contain class=""></v-img>
                </v-col>

                <!-- <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                  <v-btn color="primary">Contratar</v-btn>
                </v-col> -->

                <v-col cols="12" sm="12" md="6" lg="4" xl="4">
                  <p class="precio">Mejor cotización</p>
                  <p class="precio">{{ MejorCotizacion.cotizacion.total ? this.$formatMoney(MejorCotizacion.cotizacion.total) : "No disponible"}}</p>

                  <v-card v-if="MejorCotizacion.nombre == 'aig'" href="tel:+525588770550"
                    class="bottonLlamada d-flex align-center justify-center text-subtitle-1 text-white" color="primary" dark height="55">
                    <v-card-text>
                      <span class="white--text"> Marcar a un asesor <br> 55 8877-0550 </span>
                    </v-card-text>
                  </v-card>

                  <v-card v-if="MejorCotizacion.nombre == 'gnp'" href="tel:+525588770551"
                    class="bottonLlamada d-flex align-center justify-center text-subtitle-1" color="primary" dark height="55">
                    <v-card-text>
                      <span class="white--text">Marcar a un asesor <br> 55 8877-0551</span>
                    </v-card-text>
                  </v-card>

                  <v-card v-if="MejorCotizacion.nombre == 'qualitas'" href="tel:+525588770552"
                    class="bottonLlamada d-flex align-center justify-center text-subtitle-1" color="primary" dark height="55">
                    <v-card-text>
                      <span class="white--text">Marcar a un asesor <br> 55 8877-0552 </span>
                    </v-card-text>
                  </v-card>
                </v-col>
              </template>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- cotizaciones individuales del comparador -->
    <v-row class="justify-center">
      <v-col cols="12" sm="12" md="3" lg="3" xl="3" v-for="(asegob, i) in aseguradoraMeta.aseguradoras" :key="i">
        <CotizacionIndividualComparador :aseguradora="asegob.nombre" :logo="asegob.logo" :usuarioJson="usuarioJson"
          :vehiculo="vehiculo" :modelo="modelo" :marca="marca" :submarca="submarca" :plan.sync="plan" :origen="origen" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CotizacionIndividualComparador from './CotizacionIndividualComparador.vue'

export default {
  name: "cotizacion.CotizacionComparador",
  props: ["usuarioJson", "vehiculo", "marca", "submarca", "modelo", "origen"],
  components: {
    CotizacionIndividualComparador
  }, data: function () {
    return {
      plan: 'amplia',
      reload: false,
    }
  }, computed: {
    ...mapState(["aseguradoraMeta", 'MejorCotizacion', 'loading']),
  }, watch: {
    plan: function (val) {
      this.$store.commit('setPlanSeleccionado', val);
    }
  }, methods: {},
}
</script>

<style scoped>
.bottonLlamada {
    background-image: url('../../../../assets/icons/segurify_icono_llamada_final_v1_270922_001.svg');
    background-repeat: no-repeat;
    background-size: 80%;
    background-position: bottom;
}

@media (min-width: 390px) {
  .bottonLlamada {
    background-size: 100%;
    background-position: center;
  }
}

@media (min-width: 460px) {
  .bottonLlamada {
    background-size: 90%;
  }
}

@media (min-width: 570px) {
  .bottonLlamada {
    background-size: 75%;
  }
}

@media (min-width: 700px) {
  .bottonLlamada {
    background-size: 50%;
  }
}

@media (min-width: 850px) {
  .bottonLlamada {
    background-size: 40%;
  }
}

@media (min-width: 975px) {
  .bottonLlamada {
    background-size: 100%;
  }
}

</style>