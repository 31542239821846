<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-row dense>
          <template v-if="aseguradoraMeta.has_banner">
            <v-col cols="2" sm="2" md="2" lg="2" xl="1" class="d-sm-none d-md-flex" v-show="!$vuetify.breakpoint.xs">
              <Banner />
            </v-col>
          </template>

          <v-col cols="12" sm="12" :md="aseguradoraMeta.has_banner ? '10' : '12'"
            :lg="aseguradoraMeta.has_banner ? '10' : '12'" :xl="aseguradoraMeta.has_banner ? '11' : '12'">
            <v-row class="mt-2">
              <v-col cols="12" :class="'text-center'">
                <h1 :class="$vuetify.breakpoint.md ? 'text-h6' : 'text-h5'">¡Cotiza tu seguro de auto en segundos!</h1>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <!-- <v-btn @click="tagManager">tag</v-btn> -->
                <Stepper />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card flat v-if="aseguradoraMeta.has_content">
      <!-- <v-card-text> -->
      <ComparadorContent />
      <!-- </v-card-text> -->
    </v-card>

    <v-btn icon @click="tagManager" class="call-btn" :class="{ 'btn-small': $vuetify.breakpoint.smAndDown }">
      <v-img v-if="aseguradoraMeta.has_telefono"
        :class="$vuetify.breakpoint.xs || $vuetify.breakpoint.sm ? 'buttonCallDown' : 'buttonCallUP'" :src="telefono"
        :width="$vuetify.breakpoint.smAndDown ? 175 : 230" alt="Seguros Segurify - Cotizador de Seguros de Auto">
        <label class="font-weight-bold white--text"
          style="">{{ $vuetify.breakpoint.smAndDown ? '-' : 'Obtén ' }}5% adicional</label>
        <h1 class="font-weight-bold white--text" style="">
          ¡Llámanos!</h1>
      </v-img>
    </v-btn>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import Banner from "./components/PromoBanner.vue";
import Stepper from "./components/Stepper.vue";
import ComparadorContent from './home/static/ComparadorHome.vue';
import telefono from "@/assets/icons/segurify_icono_llamada_v5_190922.svg";

window.addEventListener("beforeunload", (evento) => {
        evento.preventDefault();
        evento.returnValue = "";
        return "";
});

export default {
  name: "Home",
  data: () => ({
    telefono: telefono,
  }),
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  mounted() {
    this.$meta().refresh();
    this.setCodigoAbandono(this.$uuidAbandono());
  },
  methods: {
    ...mapMutations(['setCodigoAbandono']),
    tagManager() {
      if (this.aseguradoraMeta.tag) {
        this.$gtag.event("lead_success_phone", {
          'event_category': 'click',
          'event_label': 'Segurify Auto',
        })
      }

      let a = document.createElement('a');
      a.href = "tel:+525588730915";
      a.click();
    }
  },
  components: {
    Banner,
    Stepper,
    ComparadorContent,
  },
};
</script>

<style scoped>
.v-btn--absolute.v-btn--top,
.v-btn--fixed.v-btn--top {
  top: 90px;
}

.buttonCallUP {
  position: fixed;
  top: 90px;
  right: 0;
}

.buttonCallDown {
  position: fixed;
  bottom: 65px;
  right: 0;
}

.call-btn label {
  position: relative;
  top: 14px;
  left: -40px;
  font-size: 0.75em;
}

.call-btn h1 {
  position: relative;
  top: 40px;
  left: -14px;
  font-size: 0.75em;
}

.call-btn.btn-small label {
  top: 10px;
  left: -32px;
}

.call-btn.btn-small h1 {
  top: 27px;
  font-size: 0.50rem;
  left: -12px;
}
</style>