export default async function downloadCotizacion(
  cotizaciones,
  name,
  lastname,
  aseguradora,
  isCompara
) {
  let config = {
    responseType: "blob",
  };
  const url = isCompara
    ? "/v1/cotizacion/pdf"
    : `/v1/cotizacion/pdf/aseguradora/${aseguradora}`;

  let { data } = await window.axios.post(url, cotizaciones, config);
  require("downloadjs")(
    data,
    `${name}_${lastname}_CotizacionSegurify.pdf`,
    "application/pdf"
  );
  // download(
  //     data,
  //     "cotizacion.pdf",
  //     "application/pdf"
  // );
}
