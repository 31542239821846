<template>
  <v-stepper v-model="e1" >
    <v-stepper-header>
        <v-stepper-step-icon :complete="e1 > 1" step="1" class="text-truncate">
          <template #icon="step">
            <v-avatar :color="aseguradoraMeta.step" v-if="step.complete">
              <v-img :src="auto" alt="Seguros Segurify - Cotizador de Seguros de Auto"/>
            </v-avatar>

            <v-avatar v-else :color="aseguradoraMeta.step">
              <v-img :src="auto" alt="Seguros Segurify - Cotizador de Seguros de Auto"/>
            </v-avatar>
          </template>
          
          <span
            class="headline text-caption ml-2 d-inline-block text-truncate" 
            style="max-width: 20rem;"
            :class="e1 > 1 ? 'success--text' : 'primary--text'">
              Tu Vehículo {{ modeloSeleccionado }} {{marcaSeleccionada && marcaSeleccionada.marca}} {{submarcaSeleccionada && submarcaSeleccionada.nombre}} {{vehiculoSeleccionado && vehiculoSeleccionado.nombre}}
          </span>
        </v-stepper-step-icon>

        <v-divider> </v-divider>

        <v-stepper-step-icon :complete="e1 > 2" step="2">
          <template #icon="step">
            <v-avatar :color="aseguradoraMeta.step" v-if="step.isActive">
              <v-img :src="cliente" alt="Seguros Segurify - Cotizador de Seguros de Auto"/>
            </v-avatar>

            <v-avatar :color="aseguradoraMeta.step" v-else-if="step.complete">
              <v-img :src="cliente" alt="Seguros Segurify - Cotizador de Seguros de Auto"/>
            </v-avatar>

            <v-avatar v-else color="#757575">
              <v-img :src="cliente" alt="Seguros Segurify - Cotizador de Seguros de Auto"/>
            </v-avatar>
          </template>

          <span class="headline text-caption ml-2" :class="e1 > 2 ? 'success--text' : 'primary--text'">
            Datos del conductor
          </span>
        </v-stepper-step-icon>
  
        <v-divider> </v-divider>

        <v-stepper-step-icon step="3">
          <template #icon="step">
            <v-avatar :color="aseguradoraMeta.step" v-if="step.isActive">
              <v-img
                :src="cotizacion"
                alt="Seguros Segurify - Cotizador de Seguros de Auto"
              ></v-img>
            </v-avatar>
            <v-avatar v-else color="#757575">
              <v-img
                :src="cotizacion"
                alt="Seguros Segurify - Cotizador de Seguros de Auto"
              ></v-img>
            </v-avatar>
          </template>

          <span class="headline text-caption ml-2" :class="e1 > 3 ? 'success--text' : 'primary--text'">
            Cotización
          </span>
        </v-stepper-step-icon>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <SeleccionarModelo @seleccionarModelo="seleccionarModelo" v-if="paso=='modelo'"/>
        <SeleccionarMarca :modelo="modeloSeleccionado" @back="regresar" @seleccionarMarca="seleccionarMarca" v-if="paso=='marca'"/>
        <SeleccionarSubmarca :modelo="modeloSeleccionado" @back="regresar" :marca="marcaSeleccionada.id" @seleccionarSubmarca="seleccionarSubmarca" v-if="paso=='submarca'"/>
        <SeleccionarVehiculo :modelo="modeloSeleccionado" @back="regresar" :marca="marcaSeleccionada.id" :submarca="submarcaSeleccionada.id" @seleccionarVehiculo="seleccionarVehiculo" v-if="paso=='version'"/>
      </v-stepper-content>

      <v-stepper-content step="2">
        <FormularioDatos @back="regresar" @cotiza="cotiza"/>
      </v-stepper-content>

      <v-stepper-content step="3">
        <Cotizacion v-if="e1==3" :usuarioJson="usuarioJson" :vehiculo="vehiculoSeleccionado" :marca="marcaSeleccionada" :submarca="submarcaSeleccionada" :modelo="modeloSeleccionado" />
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>
<script>

import auto from "@/assets/icons/icono_vehiculo.svg";
import cliente from "@/assets/icons/icono_conductor.svg";
import cotizacion from "@/assets/icons/icono_cotizacion.svg";


import SeleccionarMarca from './SeleccionarMarca.vue';
import SeleccionarModelo from './SeleccionarModelo.vue';
import SeleccionarSubmarca from './SeleccionarSubmarca.vue';
import SeleccionarVehiculo from './SeleccionarVersion.vue';
import FormularioDatos from './FromularioDatos.vue';
import Cotizacion from './cotizacion/Cotizacion.vue';

import { mapState } from 'vuex'
export default {
  data() {
    return {
      auto: auto,
      cliente: cliente,
      cotizacion: cotizacion,
      modeloSeleccionado: null,
      marcaSeleccionada:null,
      submarcaSeleccionada:null,
      vehiculoSeleccionado:null,
      usuarioJson:null,
      e1: 1,
      paso:'modelo'
    };
  },
  computed: {
    ...mapState(['aseguradoraMeta'])
  },
  methods: {
    regresar(step){
      if(step=='version'){
        this.e1=1;
      }
      this.paso = step;
    },
    seleccionarModelo(modelo) {
      this.modeloSeleccionado = modelo;
      this.paso='marca';
    },
    seleccionarMarca(marca){
      this.marcaSeleccionada = marca;
      this.paso='submarca';
    },
    seleccionarSubmarca(submarca){
      this.submarcaSeleccionada = submarca;
      this.paso='version';
    },
    seleccionarVehiculo(vehiculo){
      this.e1=2;
      this.vehiculoSeleccionado = vehiculo;
    },
    cotiza(usuarioJson){
      if(!usuarioJson.valid){
        return false;
      }
      this.usuarioJson=usuarioJson.data;
      this.e1=3;
    }
  },

  components:{
    SeleccionarMarca,
    SeleccionarModelo,
    SeleccionarSubmarca,
    SeleccionarVehiculo,
    FormularioDatos,
    Cotizacion
  }
};
</script>
